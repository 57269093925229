import React, { useState } from 'react';
import { useLocation, useNavigate,  } from 'react-router-dom';
import Http from '../../Services/Http';

function ResetPassword() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const [disabled, setdisabled] = useState(false)
    const navigate = useNavigate();
    const [password, setPassword] = useState(''); // Corrected variable name
    const Submitpassword = async (e) => { // Corrected function name
        e.preventDefault();
        if (password) {
            setdisabled(true)
            try {
                let res = await Http({
                    url: `/auth/resetpassword/?token=${token}`, // Pass token to URL
                    method: 'post',
                    data: { password }
                });
                if (res?.data?.success === true) {
                    alert('Password Update successfully');
                    setTimeout(() => {
                        navigate('/login');
                    }, 1000);
                } else if (res?.data?.message === 'Token not found or expire') {
                    alert('Token not found or expire');
                    navigate('/forgetpage');
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            alert('Please enter the password.'); // Update alert message
        }
    }
    return (
        <div>
            <section className="bg-gray-50 dark:bg-gray-900">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                    <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
                        <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            Change Password
                        </h2>
                        <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5" action="#">
                            <div>
                                <label
                                    htmlFor="password"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    New Password
                                </label>
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    onChange={(e) => setPassword(e.target.value)} // Update function name
                                    placeholder="••••••••"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    required=""
                                />
                            </div>
                            <div className="flex items-start">
                                <div className="flex items-center h-5">
                                    <input
                                        id="acceptTerms"
                                        aria-describedby="acceptTerms"
                                        type="checkbox"
                                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                                        required=""
                                    />
                                </div>
                                <div className="ml-3 text-sm">
                                    <label
                                        htmlFor="acceptTerms"
                                        className="font-light text-gray-500 dark:text-gray-300"
                                    >
                                        I accept the{" "}
                                        <span className="font-medium text-primary-600 hover:underline dark:text-primary-500">
                                            Terms and Conditions
                                        </span>
                                    </label>
                                </div>
                            </div>
                            {
                                disabled ?  
                                <button
                                type="submit"
                                onClick={Submitpassword} // Update function name
                                className="w-full bg-blue-600 text-white  bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                            >
                                Submit password
                            </button>
                             
                                :
                                <button
                                type="submit"
                                disabled
                                className="w-full bg-blue-600 text-white  bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                            >
                                Submit password
                            </button>
                            }
                           
                        </form>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ResetPassword;
