import React, { useContext, useEffect, useState } from 'react';
import Http from '../../../Services/Http';
import { FaFileCsv } from "react-icons/fa";
import Updatefranchise from './Updatefranchise';
import { MyContext } from '../../../Context/MyContextProvider';


function TotalFranchise() {
  const [franchise, setFranchise] = useState([]);
  const user = localStorage.getItem('userdata');

  const loginUser = JSON.parse(user);
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(true);
  const { editFranchise, seteditFranchise } = useContext(MyContext);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Http({
          url: `/franchise/getfranchise`,
          method: "get",
          data: {}
        });
        setloading(false)
        setdata(res?.data?.franchise);
      } catch (error) {
        console.error("Error fetching data:", error);
        setloading(false)
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (loginUser.role === "director") {
      setFranchise(data);
      // setloading(false)
    } else if (loginUser?.role === "franchise") {
      const datacheck = data.filter((item) => {
        return item?._id === loginUser?.franchiserid?._id
      })
      setFranchise(datacheck)
      // setloading(false)
    }
  }, [loginUser?.role, loginUser?.franchiserid?._id, data])

  // Function to convert array of objects to CSV format
  const convertToCSV = (data) => {
    const header = Object.keys(data[0]).join(',') + '\n';
    const rows = data.map(item => Object.values(item).join(',')).join('\n');
    return header + rows;
  };

  // Function to handle download button click
  const handleDownload = () => {
    const csv = convertToCSV(franchise);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'franchise_data.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };
  const edithandler = (item) => {
    localStorage.setItem('franchiseItemData', JSON.stringify(item));
    seteditFranchise(true)
  }
  const deletefranchiseHaderl = async (item) => {
    // Display confirmation dialog
    const confirmDelete = window.confirm("Are you sure you want to delete this franchise?");
  
    if (confirmDelete) {
      let _id = item?._id;
      try {
        let res = await Http({
          url: `/franchise/deletefranchise`,
          method: "post",
          data: { _id }
        });
        console.log(res?.data?.message);
        if (res?.data?.message === "deletedeletefranchise_200") {
          window.location.reload();
        }
      } catch (error) {
        console.log(`Server Catch Error ${error}`);
      }
    }
  };
  
  return (
    <>
      {
        loading ?
          (
            <>
              <div className="w-[95%] mt-8 mx-auto">
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-700 bg-white shadow-md rounded-lg overflow-hidden">
                    <thead className="bg-gray-700">
                      <tr>
                        <th className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">

                        </th>
                        <th className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">

                        </th>
                        <th className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">

                        </th>
                        <th className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">

                        </th>

                      </tr>
                    </thead>
                    <tbody className="divide-y divide-white ">
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) :
          (
            <div className='w-[95%] mt-8 mx-auto relative'>
              <div className="w-full shadow-md sm:rounded-lg overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-700">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                        Name
                      </th>
                      <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                        Franchise Name
                      </th>
                      <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                        Full Address
                      </th>
                      <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                        Mobile Number
                      </th>
                      <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                        Email
                      </th>
                      <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                        State
                      </th>
                      <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                        District
                      </th>
                      <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                        Block
                      </th>
                      <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                        Panchayat
                      </th>
                      <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                        Village
                      </th>
                      <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                        Aadhaar Number
                      </th>
                      <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                        Status
                      </th>
                      {
                        loginUser?.role === "director" &&
                        <>
                          <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                            Edit
                          </th>
                          <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                            Delete
                          </th>
                        </>
                      }

                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 text-center ">
                    {franchise?.map((item, index) => (
                      <tr key={index}>
                        <td className={`px-6 py-4 whitespace-nowrap ${item?.status !== true ? 'bg-red-200' : ""}`}>{item?.name}</td>
                        <td className={`px-6 py-4 whitespace-nowrap ${item?.status !== true ? 'bg-red-200' : ""}`}>{item?.franchisename}</td>
                        <td className={`px-6 py-4 whitespace-nowrap ${item?.status !== true ? 'bg-red-200' : ""}`}>{item?.fulladdress}</td>
                        <td className={`px-6 py-4 whitespace-nowrap ${item?.status !== true ? 'bg-red-200' : ""}`}>{item?.mobilenumber}</td>
                        <td className={`px-6 py-4 whitespace-nowrap ${item?.status !== true ? 'bg-red-200' : ""}`}>{item?.email}</td>
                        <td className={`px-6 py-4 whitespace-nowrap ${item?.status !== true ? 'bg-red-200' : ""}`}>{item?.state}</td>
                        <td className={`px-6 py-4 whitespace-nowrap ${item?.status !== true ? 'bg-red-200' : ""}`}>{item?.district}</td>
                        <td className={`px-6 py-4 whitespace-nowrap ${item?.status !== true ? 'bg-red-200' : ""}`}>{item?.block}</td>
                        <td className={`px-6 py-4 whitespace-nowrap ${item?.status !== true ? 'bg-red-200' : ""}`}>{item?.panchayat}</td>
                        <td className={`px-6 py-4 whitespace-nowrap ${item?.status !== true ? 'bg-red-200' : ""}`}>{item?.village}</td>
                        <td className={`px-6 py-4 whitespace-nowrap ${item?.status !== true ? 'bg-red-200' : ""}`}>{item?.aadhaarnumber}</td>
                        <td className={`px-6 py-4 whitespace-nowrap ${item?.status !== true ? 'bg-red-200' : ""}`}>
                          <button className="font-medium text-blue-600 dark:text-blue-500 hover:underline">{item?.status === true ? "Approved" : "Not Approve"}</button>
                        </td>
                        {
                          loginUser?.role === "director" &&
                          <>
                            <td onClick={() => edithandler(item)} className={`px-6 py-4 whitespace-nowrap ${item?.status !== true ? 'bg-red-200' : ""}`}>
                              <button className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</button>
                            </td>
                            <td onClick={() => deletefranchiseHaderl(item)} className={`px-6 py-4 whitespace-nowrap ${item?.status !== true ? 'bg-red-200' : ""}`}>
                              <button className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Delete</button>
                            </td>
                          </>
                        }

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* Download button */}
              <div className="flex justify-center mt-1 cursor-pointer absolute top-0 right-2 text-white bg-gray-700">
                <div onClick={handleDownload} className=" font-bold py-2 px-4 rounded">
                  <FaFileCsv size={30} />
                </div>
              </div>
            </div>
          )
      }
      {
        editFranchise && <Updatefranchise />
      }
    </>
  );
}

export default TotalFranchise;
