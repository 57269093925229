import React, { useContext, useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import FroalaEditor from 'froala-editor';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';

import Http from '../../../Services/Http';
import { MyContext } from '../../../Context/MyContextProvider';
import { IoCloseSharp } from 'react-icons/io5';

function UpdateCourse() {
  const editorRef = useRef(null);
  const { setCourseEdit } = useContext(MyContext)
  const courseItemData = JSON.parse(localStorage.getItem('courseItemData'))
  const [editorInstance, setEditorInstance] = useState(null);
  const [editorContent, setEditorContent] = useState('');
  const [image, setimage] = useState('')

  useEffect(() => {
    if (editorRef.current) {
      const editor = new FroalaEditor(editorRef.current, {
        initOnClick: false,
        events: {
          initialized: () => {
            const content = editor.html.get();
            setEditorContent(content);
          },
          contentChanged: () => {
            const content = editor.html.get();
            setEditorContent(content);
          }
        }
      });

      setEditorInstance(editor);

      return () => {
        editor.destroy();
      };
    }
  }, []);

  useEffect(() => {
    if (editorInstance && courseItemData?.discription) {
      editorInstance.html.set(courseItemData.discription);
    }
  }, [editorInstance, courseItemData]);
  
  
  
  
  

  const handleImageUpload = (event) => {
    const imageFile = event.target.files[0];
    if (imageFile) {
      const reader = new FileReader();
      reader.onload = () => {
        const imageData = reader.result;
        // Do something with the imageData
        setimage(imageData)
      };
      reader.readAsDataURL(imageFile);
    }
  }


  // Define initialValues, validationSchema, and onSubmit outside of JSX
  const initialValues = {
    name: `${courseItemData?.name || ""}`,
    duration: `${courseItemData?.duration || ""}`,
    fees: `${courseItemData?.fees || ""}`,
    eligibility: `${courseItemData?.eligibility || ""}`
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, 'Must be at least 3 characters')
      .required('Required'),

    duration: Yup.string()
      .required('Required'),
    fees: Yup.number().min(5, 'Must be at least 5').required('Required'),
    eligibility: Yup.string().notOneOf(['Select'], 'Required'),
  });

  const onSubmit = async (values) => {
    const name = values?.name
    const discription = editorContent
    const duration = values?.duration
    const fees = values?.fees
    const eligibility = values?.eligibility
    try {
      let res = await Http({
        url: `/course/updatecourse`,
        method: `patch`,
        data: {_id:courseItemData?._id, name, discription, duration, fees, eligibility, image }
      });
      if (res.data.message === "Course updated successfully") {
        setCourseEdit(false)
      }
    } catch (error) {
      console.log(`catch network error ${error}`);
    }
  };
  const handleClose = ()=>{
    setCourseEdit(false)
  }
  return (
    <div>
      <div className="w-full  mx-auto fixed z-50 bg-black bg-opacity-50 inset-0">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form className="w-full ">
            <div className="w-[80%] mt-10 h-screen  bg-white mx-auto rounded-md border border-gray-200 flex justify-between">
              <div className="w-[80%]">
                <div className="w-full py-3 px-4 bg-gray-700 rounded-tl-md text-white mx-auto border-b">
                  <h1 className="text-xl tracking-wider font-bold ">Create Courses</h1>
                </div>

                <div className="w-full flex gap-4 px-6 ">
                  <div className="w-full ">
                    <label className="block py-2 px-2 rounded-sm tracking-wide">Name of Course </label>
                    <Field
                      type="text"
                      name="name"
                      className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm"
                    />
                    <ErrorMessage name="name" component="p" className="text-red-600 text-sm mt-1 ml-2" />
                  </div>

                </div>
                <div className='w-full p-4 ml-5 '>
                  <input type='file' accept="image/*" onChange={handleImageUpload} />
                </div>
                <div className="w-full flex gap-4 px-6">
                  <div className="w-full ">
                    <label className="block py-2 px-2 rounded-sm tracking-wide">Course Description</label>
                    <textarea
                      name="description"
                      ref={editorRef}
                      className="block w-full h-28 border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm"
                    ></textarea>
                    {/* <p name="discription" component="p" className="text-red-600 text-sm mt-1 ml-2" >{disMeggae && <>Description is Required</>}</p> */}
                  </div>
                </div>
                <div className="w-full flex gap-4 px-6 justify-between">
                  <div className="w-full md:w-[30%]">
                    <label className="block py-2 px-2 rounded-sm tracking-wide">Duration </label>
                    <Field
                      type="text"
                      name="duration"
                      className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm"
                    />
                    <ErrorMessage name="duration" component="p" className="text-red-600 text-sm mt-1 ml-2" />
                  </div>
                  <div className="w-full md:w-[30%]">
                    <label className="block py-2 px-2 rounded-sm tracking-wide">Fees</label>
                    <Field
                      type="number"
                      name="fees"
                      className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm"
                    />
                    <ErrorMessage name="fees" component="p" className="text-red-600 text-sm mt-1 ml-2" />
                  </div>
                  <div className="w-full md:w-[30%]">
                    <label className="block py-2 px-2 rounded-sm tracking-wide">Eligibility</label>
                    <Field
                      as="select"
                      name="eligibility"
                      className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm"
                    >
                      <option value="Select">Select</option>
                      <option value="10th">10th</option>
                      <option value="12th">12th</option>
                    </Field>
                    <ErrorMessage name="eligibility" component="p" className="text-red-600 text-sm mt-1 ml-2" />
                  </div>
                </div>


              </div>
              <div className="w-[20%] border-l border-gray-300 my-5 relative">
                <div className="w-full flex justify-evenly">
                  <div onClick={handleClose} className='absolute top-0 right-2 cursor-pointer'>
                    <IoCloseSharp size={30} />
                  </div>
                  <button
                    type="submit"
                    className="focus:outline-none text-white bg-[#ff0000] hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-md text-sm px-5 py-2.5 me-2 mb-2 dark:bg-[#ff0000] dark:hover:bg-red-700 dark:focus:ring-red-900"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    
    </div>
  );
}

export default UpdateCourse;
