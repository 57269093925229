import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import { useParams } from 'react-router-dom';
import Http from '../../../Services/Http';
import { MdOutlineCurrencyRupee } from 'react-icons/md';
import MobileNavbar from '../../Components/MobileNavbar';

function ThisCourse() {
    const { courseid } = useParams();
    const [course, setCourse] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await Http({
                    url: `/course/getonecourse`,
                    method: 'post',
                    data: { _id: courseid }
                });
                const data = res?.data?.course;
                setCourse(data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log('course page error', error);
            }
        };
        fetchData();
    }, [courseid]);

    return (
        <div>
            <div className='hidden md:block'>
                <Navbar />
            </div>
            <div className=' md:hidden'>
                <MobileNavbar />
            </div>
            <div className="container mx-auto mt-10 md:my-28 bg-white p-5 md:p-10">
                {loading ? (
                    <p>Loading...</p>
                ) : course ? (
                    <section className="text-gray-600 body-font overflow-hidden">
                        <div className="flex flex-col lg:flex-row">
                            <div className="w-full lg:w-1/2">
                                <img
                                    alt="course"
                                    className="w-full h-auto object-cover object-center rounded"
                                    src={course?.image}
                                />
                            </div>
                            <div className="w-full lg:w-1/2 lg:pl-10 lg:py-6 mt-6 lg:mt-0">
                                <h2 className="text-sm title-font text-gray-500 tracking-widest">
                                    Course NAME
                                </h2>
                                <h1 className="text-gray-900 text-3xl title-font font-medium mb-1">
                                    {course?.name}
                                </h1>
                                <div className="flex mb-4">
                                    {/* Rating SVGs */}
                                </div>
                                <div
                                    className="leading-relaxed"
                                    dangerouslySetInnerHTML={{
                                        __html: course?.discription
                                    }}
                                ></div>
                                <div className="flex mt-6 items-center pb-5 border-b-2 border-gray-300 mb-5">
                                    <div className="flex bg-gray-100 py-2 rounded-md px-5">
                                        <span className="mr-3">Duration</span>
                                        <div>{course?.duration}</div>
                                    </div>
                                    <div className="flex ml-6 items-center bg-gray-100 py-2 rounded-md px-5">
                                        <span className="mr-3">Eligibility</span>
                                        <div className="relative">
                                            {course?.eligibility}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center">
                                    <span className="title-font font-medium text-2xl text-gray-900 flex ">
                                        <MdOutlineCurrencyRupee className="mt-1" size={25} />
                                        {course?.fees}
                                    </span>
                                    {/* Buttons */}
                                </div>
                            </div>
                        </div>
                    </section>
                ) : (
                    <p>No course found with ID: {courseid}</p>
                )}
            </div>
            <Footer />
        </div>
    );
}

export default ThisCourse;
