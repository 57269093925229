import React, { useState } from 'react'
import Footer from '../../Components/Footer'
import Navbar from '../../Components/Navbar'
import MobileNavbar from '../../Components/MobileNavbar'
import logo from '../../../Assets/logo512.png.png'
import Http from '../../../Services/Http'

function Cverify() {
    const [certificateCode, setCertificateCode] = useState('');
    const [verificationStatus, setVerificationStatus] = useState('');
    const [certificate, setcertificate] = useState({})
    console.log(certificate);
    const handleVerify = async () => {
        try {
            let res = await Http({
                url: `/payment/certificateverfy`,
                method: `post`,
                data: { serialno: certificateCode }
            });

            if (res.data?.success === true) {
                setcertificate(res?.data?.certificate)
                setVerificationStatus('Certificate Verified!');
            } else if (res.data?.success === false) {
                setVerificationStatus('Invalid Certificate Number');
            }
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div>
            <div className='hidden md:block'>
                <Navbar />
            </div>
            <div className=' md:hidden'>
                <MobileNavbar />
            </div>
            <div className='mt-10 mb-10 md:mt-28'>
                <div className="flex items-center justify-center">
                    <div className="w-[90%] md:w-[70%] mx-auto bg-white  rounded-lg shadow-md md:flex">
                        <div className="w-full md:w-96 flex-shrink-0 mr-4 bg-[#0a2a8a] p-8">
                            <img
                                className="h-full w-full"
                                src={logo}
                                alt="Company Logo"
                            />
                        </div>
                        <div className="w-full p-8">
                            <h1 className="text-3xl font-semibold mb-6 text-center">Certificate Verification</h1>
                            <div className="w-full md:w-[40%] mx-auto">
                                <input
                                    type="text"
                                    placeholder="Enter certificate code"
                                    className="w-full border border-gray-300 rounded-md px-3 py-2 mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    value={certificateCode}
                                    onChange={(e) => setCertificateCode(e.target.value)}
                                />
                                <button
                                    className="w-full bg-[#0a2a8a] text-white rounded-md py-2 transition duration-300 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    onClick={handleVerify}
                                >
                                    Verify Certificate
                                </button>
                                <p className="mt-4 text-center">{verificationStatus}</p>
                                <div className="mt-4 text-center">
                                    <table className="table-auto mx-auto">
                                        <tbody>
                                            <tr>
                                                <td className="border px-4 py-2">Name:</td>
                                                <td className="border px-4 py-2">{certificate?.studentid?.studentname}</td>
                                            </tr>
                                            <tr>
                                                <td className="border px-4 py-2">Father Name:</td>
                                                <td className="border px-4 py-2">{certificate?.studentid?.fathername}</td>
                                            </tr>
                                            <tr>
                                                <td className="border px-4 py-2">Certificate No:</td>
                                                <td className="border px-4 py-2">{certificate?.serialno}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Cverify