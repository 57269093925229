import React from 'react'
import Navbar from '../../Components/Navbar'
import MobileNavbar from '../../Components/MobileNavbar'
import Footer from '../../Components/Footer'

function Contact() {
    return (
        <div>
            <div className='hidden md:block'>
                <Navbar />
            </div>
            <div className=' md:hidden'>
                <MobileNavbar />
            </div>
            <div className='w-[95%] md:w-[80%] mx-auto mt-6 md:mt-28 mb-10'>
                <section className="bg-white dark:bg-gray-900 rounded-md">
                    <div className="container px-6 py-12 mx-auto">
                        <div>
                            <p className="font-medium text-blue-500 dark:text-blue-400">Contact us</p>
                            <h1 className="mt-2 text-2xl font-semibold text-gray-800 md:text-3xl dark:text-white">Get in touch</h1>
                            <p className="mt-3 text-gray-500 dark:text-gray-400">Our friendly team would love to hear from you.</p>
                        </div>
                        <div className="grid grid-cols-1 gap-6 mt-10 md:grid-cols-2 lg:grid-cols-3">
                            <div className="flex items-center space-x-4">
                                <span className="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80 dark:bg-gray-800">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                    </svg>
                                </span>
                                <div>
                                    <h2 className="mt-4 text-base font-medium text-gray-800 dark:text-white">Email</h2>
                                    <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">Our friendly team is here to help.</p>
                                    <p className="mt-2 text-sm text-blue-500 dark:text-blue-400">ajktrust@gmail.com</p>
                                </div>
                            </div>
                            <div className="flex items-center space-x-4">
                                <span className="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80 dark:bg-gray-800">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                    </svg>
                                </span>
                                <div>
                                    <h2 className="mt-4 text-base font-medium text-gray-800 dark:text-white">Office</h2>
                                    <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">Dadanpur, Maker, Pir Maker, </p>
                                    <p className="mt-2 text-sm text-blue-500 dark:text-blue-400">Saran Bihar 841215</p>
                                </div>
                            </div>
                            <div className="flex items-center space-x-4">
                                <span className="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80 dark:bg-gray-800">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                    </svg>
                                </span>
                                <div>
                                    <h2 className="mt-4 text-base font-medium text-gray-800 dark:text-white">Phone</h2>
                                    <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">All Days from 7am to 6pm.</p>
                                    <p className="mt-2 text-sm text-blue-500 dark:text-blue-400">+91 7004322539</p>
                                </div>
                            </div>
                            <div className="h-96 lg:h-auto">
                                <iframe width="100%" height="100%" frameborder="0" title="map" marginheight="0" marginwidth="0" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14359.018178359429!2d84.90345384999999!3d25.8775548!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3992b48b86231adf%3A0x87c29b9df260cc5f!2sKatasa%2C%20Bihar!5e0!3m2!1sen!2sin!4v1710681294737!5m2!1sen!2sin"></iframe>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Footer />
        </div>
    )
}

export default Contact