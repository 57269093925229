import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Http from '../../../Services/Http'

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    number: Yup.string().required('Mobile number is required').min(10,'mobile number mimimun 10 digit').max(10,"mobile number maxmix 10 digit"),
    message: Yup.string().required('Message is required'),
});

function Location() {
    const initialValues = {
        name: '',
        email: '',
        number: '',
        message: '',
    };

    const onSubmit = async (values, { resetForm }) => {
        const name = values?.name;
        const email = values?.email;
        const number = values?.number;
        const message = values?.message;
        try {
            let res = await Http({
                url:`/query/create_student_query`,
                method:"post",
                data:{name,email,number,message ,catogary:"student"},
            });
            if (res?.data?.message === "savedQuery_201") {
                resetForm();
            }
        } catch (error) {
            console.log(`server error ${error}`);
        }
      
    };

    return (
        <div className='w-full bg-white'>
            <section className="text-gray-600 body-font relative">
                <div className="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
                    <div className="lg:w-2/3 md:w-1/2  bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
                        <iframe
                            width="100%"
                            height="100%"
                            className="absolute inset-0 "
                            frameBorder="0"
                            title="map"
                            marginHeight="0"
                            marginWidth="0"
                            scrolling="no"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14359.018178359429!2d84.90345384999999!3d25.8775548!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3992b48b86231adf%3A0x87c29b9df260cc5f!2sKatasa%2C%20Bihar!5e0!3m2!1sen!2sin!4v1710681294737!5m2!1sen!2sin" 
                            style={{
                                filter: 'grayscale(1) contrast(1.2) opacity(0.4)',
                            }}
                        ></iframe>

                        <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
                            <div className="lg:w-1/2 px-6">
                                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">ADDRESS</h2>
                                <p className="mt-1">Dadanpur, Maker, Pir Maker, Saran Bihar 841215</p>
                            </div>
                            <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">EMAIL</h2>
                                <button onClick={() => window.location.href = "mailto:ajktrust@gmail.com"} className="text-indigo-500 leading-relaxed">ajktrust@gmail.com</button>
                                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">PHONE</h2>
                                <p className="leading-relaxed">+91 7004322539</p>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
                        <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">Contact US</h2>
                        <p className="leading-relaxed mb-5 text-gray-600">Post-ironic portland shabby chic echo park, banjo fashion axe</p>
                        
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            {({ touched, errors, isSubmitting }) => (
                                <Form>
                                    <div className="relative mb-4">
                                        <label htmlFor="name" className="leading-7 text-sm text-gray-600">Name</label>
                                        <Field type="text" id="name" name="name" className={`w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out ${touched.name && errors.name ? 'border-red-500' : ''}`} />
                                        <ErrorMessage name="name" component="div" className="text-red-500 text-xs mt-1" />
                                    </div>
                                    <div className="relative mb-4">
                                        <label htmlFor="email" className="leading-7 text-sm text-gray-600">Email</label>
                                        <Field type="email" id="email" name="email" className={`w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out ${touched.email && errors.email ? 'border-red-500' : ''}`} />
                                        <ErrorMessage name="email" component="div" className="text-red-500 text-xs mt-1" />
                                    </div>
                                    <div className="relative mb-4">
                                        <label htmlFor="number" className="leading-7 text-sm text-gray-600">Mobile Number</label>
                                        <Field type="number" id="number" name="number" className={`w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out ${touched.number && errors.number ? 'border-red-500' : ''}`} />
                                        <ErrorMessage name="number" component="div" className="text-red-500 text-xs mt-1" />
                                    </div>
                                    <div className="relative mb-4">
                                        <label htmlFor="message" className="leading-7 text-sm text-gray-600">Message</label>
                                        <Field as="textarea" id="message" name="message" className={`w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out ${touched.message && errors.message ? 'border-red-500' : ''}`} />
                                        <ErrorMessage name="message" component="div" className="text-red-500 text-xs mt-1" />
                                    </div>
                                    <button type="submit" disabled={isSubmitting} className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Submit</button>
                                </Form>
                            )}
                        </Formik>

                        <p className="text-xs text-gray-500 mt-3">Chicharrones blog helvetica normcore iceland tousled brook viral artisan.</p>
                    </div>
                </div>
            </section>
        </div> 
    );
}

export default Location;
