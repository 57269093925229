import React, { useState } from 'react'
import Sidebar from '../Pages/sidebar'
import { Outlet } from 'react-router-dom'
import { MdKeyboardArrowLeft ,MdKeyboardArrowRight} from "react-icons/md";
import { IoMenu } from "react-icons/io5";
import { IoClose } from "react-icons/io5";


function Home() {
  const [toggle, settoggle] = useState(true)
  const toggleArrow = ()=>{
    settoggle((pri)=>!pri)
  }
  return (
    <div className="flex  bg-[#bed6c5] overflow-hidden h-screen">
      <div className="w-auto  sm: h-screen bg-gray-700 border-r">
        {
          toggle && <Sidebar />
        }
        
      </div>
      <div className="flex-1 overflow-y-auto bg-[#F1F1F1]">
        <div className='fixed  hidden sm:block top-[50%] cursor-pointer' onClick={toggleArrow}>
          {
            toggle ? <MdKeyboardArrowLeft size={35}/> : <MdKeyboardArrowRight size={35}/>
          }
        </div>
        <div className={`fixed bg-gray-700 rounded-sm  sm:hidden top-[1.5%] left-2 z-50 text-white cursor-pointer`} onClick={toggleArrow}>
          {
            toggle ? <IoClose size={35} className='fixed   sm:hidden top-[1.5%] left-[50%] z-50 '/> : <IoMenu size={35}/>
          }
        </div>
        <Outlet />
      </div>
    </div>
  )
}

export default Home