import AppRouter  from './Router/Router';
import './App.css';

function App() {
  return (
   <>
     <AppRouter/>
     
   </>
  );
}

export default App;
