import React, { useState } from 'react';
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import logo from '../../../Assets/logo512.png.png'

function DashboardPage() {
    const navigate = useNavigate()
    const [isProfileVisible, setProfileVisible] = useState(false);
    const Data = localStorage.getItem('userdata');
    const userData = JSON.parse(Data);

    const toggleProfileVisibility = () => {
        setProfileVisible(!isProfileVisible);
    };
    const logOutHandler = () => {
        localStorage.clear();
        setTimeout(() => {
            navigate('/login')
        }, 2000)

    }
    return (
        <>
            <div className='relative'>
                <div className="w-full bg-gray-700 border-b">
                    <div className="w-[95%] mx-auto px-4 py-2 flex justify-end">
                        <div className="w-[80%] flex justify-end">
                            <div className='bg-[#FF0000] px-4 py-1 mt-1 rounded-md'>
                                <h1 className='text-white text-lg font-semibold uppercase '>{userData?.role ? userData?.role : "Loading"}</h1>
                            </div>
                        </div>
                        <div className="w-auto pl-2 flex justify-between">
                            <button type="button"
                                className="inline-flex text-white items-center justify-center w-10 h-10 font-medium focus:bg-[#e63535] rounded-full hover:bg-[#e63535] group focus:ring-4 focus:ring-[#ff0000] focus:outline-none dark:focus:ring-[#ff0000]"
                                onClick={toggleProfileVisibility}>
                                <FaUserCircle size={30} />
                            </button>
                        </div>
                    </div>
                </div>

                {isProfileVisible && (
                    <div className="absolute right-0 mr-5 z-10 flex justify-end w-64 text-sm  transition-opacity duration-300  opacity-100 ">
                        <div className="flex  items-center h-full w-auto justify-end">
                            <div className="max-w-xs">
                                <div className="bg-white shadow-xl rounded-lg py-3">
                                    <div className="photo-wrapper p-2">
                                        <img className="w-32 h-32 rounded-full mx-auto" src={logo} alt="John Doe" />
                                    </div>
                                    <div className="p-2">
                                        <h3 className="text-center text-xl text-gray-900 font-medium leading-8">{userData?.username}</h3>
                                        <div className="text-center text-gray-400 text-xs font-semibold">
                                            <p>{userData?.role}</p>
                                        </div>
                                        <table className="text-xs my-3">
                                            <tbody>
                                                {/* <tr>
                                                <td className="px-2 py-2 text-gray-500 font-semibold">Address</td>
                                                <td className="px-2 py-2">{userData.sad}</td>
                                            </tr> */}
                                                <tr>
                                                    <td className="px-2 py-2 text-gray-500 font-semibold">Mobile</td>
                                                    <td className="px-2 py-2">+91 {userData?.mobile}</td>
                                                </tr>
                                                <tr>
                                                    <td className="px-2 py-2 text-gray-500 font-semibold">Email</td>
                                                    <td className="px-2 py-2">{userData?.email}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="text-center my-3">
                                            <h1 onClick={logOutHandler} className="text-xs text-indigo-500 italic hover:underline hover:text-indigo-600 font-medium cursor-pointer ">Logout</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="w-full flex justify-center items-center h-screen">
                <div className="bg-white p-24 rounded-full shadow-md">
                    <h1 className="text-3xl font-bold mb-4">Welcome to Adarsh Jankalyan</h1>
                    <p className="text-lg text-center text-gray-700">Thank you for visiting!</p>
                    <div className="mt-4">
                       
                    </div>
                </div>
            </div>
        </>
    );
}

export default DashboardPage;
