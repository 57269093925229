import React, { useContext, useEffect, useRef, useState } from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { MyContext } from '../../../Context/MyContextProvider';
import ACertificat from '../../../Assets/A_Certificat.png';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Http from '../../../Services/Http';
import { DateTime } from 'date-time-helper-x';


function A_Createcertificate() {
    
    const { setCertificateCreate } = useContext(MyContext);
    const cartificateData = localStorage.getItem('cartificateData');
    const newCartificate = JSON.parse(cartificateData);
    // const [serialno, setserialno] = useState('');
    const [serialno, setserialno] = useState('');
    
    console.log(serialno);
    const [startdate, setstartdate] = useState('');
    const [enddate, setenddate] = useState('');
    const [issuedate, setissuedate] = useState('');
    const [grade, setgrade] = useState('');
    const [course, setcourse] = useState('');
    const [pastCartificate, setpastCartificate] = useState({});

    const [PDFbutton, setPDFbutton] = useState(false);
    const [SubmitButton, setSubmitButton] = useState(true);

    useEffect(() => {
        if (pastCartificate) {
            const { enddate, issuedate, serialno, startdate, grade, course } = pastCartificate;
            setserialno(serialno)
            setstartdate(startdate)
            setenddate(enddate)
            setissuedate(issuedate)
            setgrade(grade)
            setcourse(course)
        }
    }, [pastCartificate])

    const handleClose = () => {
        setCertificateCreate(false)
    }
    const certificateRef = useRef(null);
    const handleDownloadPDF = () => {
        const input = certificateRef.current;

        html2canvas(input, { scale: 2 }) // Increase scale for higher resolution
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('landscape', 'mm', 'a4'); // Set orientation to landscape
                pdf.addImage(imgData, 'PNG', 0, 0, 297, 210); // A4 size: 297mm × 210mm (switch width and height for landscape)
                pdf.save('ADCA-certificate.pdf');
            });
    }
    const serialnoHandler = (item) => {
        if (item) {
            const dateObject = new Date(item); // Parse the date string into a Date object
            return dateObject.getFullYear(); // Return the year extracted from the Date object
        } else {
            // Handle cases where item is null or undefined
            return null; // or throw an error, depending on your requirements
        }
    }
    ////////   create certificate  /////////

    const CertificateHandler = async () => {
        let serialno1 = `${serialnoHandler(newCartificate?.toenddate)}/A/${String(newCartificate?.aadharno).slice(-4)}`
        let startdate1 = `${DateTime(newCartificate?.startDate, "DDMMYYYY",)}`
        let enddate1 = `${DateTime(newCartificate?.toenddate, "DDMMYYYY",)}`
        let franchiseid = `${newCartificate?.franchise_or_director_id?._id}`
        try {
            let res = await Http({
                url: `/payment/createcertificate`,
                method: "post",
                data: { studentid: newCartificate?._id, franchiseid,category: "ADCA", serialno: serialno1.toUpperCase(), startdate:startdate1, enddate:enddate1, issuedate, grade, course:('Advanced Diploma in Computer Applications').toUpperCase() }
            });
            if (res?.data?.message === "newCertificate_Created_201") {
                setPDFbutton(true)
                setCertificateCreate(false)
            }
            if (res?.data?.certificate === null) {
                setSubmitButton(false)
            }
            if (res?.data?.message === "serialno_already_exists") {
                alert("Serial No is unique")
            }
        } catch (error) {
            console.log(`cartificate error ${error}`);
        }

    }
    ////////   get certificate  /////////

    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await Http({
                    url: `/payment/getcertificate`,
                    method: "post",
                    data: { studentid: newCartificate?._id, category: "ADCA" }
                });
                setpastCartificate(res?.data?.certificate);

                if (res?.data?.message === "get_sussed_200") {
                    setPDFbutton(true)


                } if (res?.data?.certificate === null) {
                    setSubmitButton(false)

                }
            } catch (error) {
                console.log(`cartificate get error ${error}`);
            }
        }
        fetchData()
    }, [newCartificate?._id])

    ////////   update certificate   /////////

    const CertificateUpdateHandler = async () => {
        try {
            let res = await Http({
                url: `/payment/updatecertificate`,
                method: "patch",
                data: { _id: pastCartificate?._id, serialno, startdate, enddate, issuedate, grade, course }
            });
            if (res?.data?.message === "updatedcertificate_Created_201") {
                setCertificateCreate(false)
            }
        } catch (error) {
            console.log(`cartificate error ${error}`);
        }
    }
    
    console.log(typeof newCartificate?.aadharno);

    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white relative w-[90%] h-[95%] my-10 mx-auto  rounded-lg shadow-lg">
                <div onClick={handleClose} className='absolute top-2 right-2 cursor-pointer'>
                    <IoCloseSharp size={30} />
                </div>
                <div className='w-full h-full overflow-scroll'>
                    <div ref={certificateRef} className='w-[297mm] h-[210mm]' style={{ position: 'relative' }}>
                        <img src={ACertificat} alt='A_Certificat' className='w-full h-full' style={{ position: 'absolute', top: 0, left: 0 }} />
                        <input
                            type='text'
                            value={`${serialnoHandler(newCartificate?.toenddate)}/A/${String(newCartificate?.aadharno).slice(-4)}`}
                            onChange={(e) => setserialno(e?.target?.value)}
                            className='absolute uppercase z-50 top-[100px] bg-transparent text-xl font-semibold text-ellipsis left-64 '
                        />
                        <h1 className='absolute z-50 top-[355px] font-semibold text-ellipsis left-96 uppercase'>{newCartificate?.studentname}</h1>
                        <h1 className='absolute z-50 top-[395px] font-semibold text-ellipsis left-96 uppercase '>{newCartificate?.fathername}</h1>
                        <input type='text' value={'Advanced Diploma in Computer Applications'} onChange={(e) => setcourse(e.target.value)} className='w-[480px] absolute z-50 bg-transparent top-[440px] font-semibold text-ellipsis left-[450px] uppercase' />
                        <h1 className='absolute z-50 top-[480px] font-semibold text-ellipsis left-96 uppercase '>{newCartificate?.franchise_or_director_id?.franchisename}</h1>
                        <input type='text' value={DateTime(newCartificate?.startDate, "DDMMYYYY",)} onChange={(e) => setstartdate(e.target.value)} className='w-32 uppercase absolute z-50 bg-transparent top-[520px] font-semibold text-ellipsis left-56 ' />
                        <input type='text' value={DateTime(newCartificate?.toenddate, "DDMMYYYY",)} onChange={(e) => setenddate(e.target.value)} className='w-32 uppercase absolute z-50 bg-transparent pl-2 top-[520px] font-semibold text-ellipsis left-[340px] ' />
                        <input type='text' value={grade} onChange={(e) => setgrade(e.target.value)} className='w-32 uppercase absolute z-50 bg-transparent pl-2 top-[520px] font-semibold text-ellipsis left-[700px] ' />
                        <input type='text' value={issuedate} onChange={(e) => setissuedate(e.target.value)} className='w-44 uppercase absolute z-50 bg-transparent pl-2 top-[660px] font-semibold text-ellipsis left-[540px] ' />
                    </div>
                </div>
                <div className='absolute top-10 right-24'>
                    {
                        PDFbutton &&
                        <button onClick={handleDownloadPDF} class="relative  inline-flex items-center justify-center px-10 py-4 overflow-hidden font-mono font-medium tracking-tighter text-white bg-gray-800 rounded-lg group">
                            <span class="absolute w-0 h-0 transition-all duration-500 ease-out bg-green-500 rounded-full group-hover:w-56 group-hover:h-56"></span>
                            <span class="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent to-gray-700"></span>
                            <span class="relative" >Download PDF</span>
                        </button>
                    }
                    {

                        SubmitButton === false &&
                        <div className='block mx-auto mt-5'>
                            <button onClick={CertificateHandler} class="relative   inline-flex items-center justify-center px-10 py-4 overflow-hidden font-mono font-medium tracking-tighter text-white bg-gray-800 rounded-lg group">
                                <span class="absolute w-0 h-0 transition-all duration-500 ease-out bg-green-500 rounded-full group-hover:w-56 group-hover:h-56"></span>
                                <span class="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent to-gray-700"></span>
                                <span class="relative" >Submit</span>
                            </button>
                        </div>
                    }

                    {
                        SubmitButton &&
                        <div className='block mx-auto mt-5'>
                            <button onClick={CertificateUpdateHandler} class="relative   inline-flex items-center justify-center px-10 py-4 overflow-hidden font-mono font-medium tracking-tighter text-white bg-gray-800 rounded-lg group">
                                <span class="absolute w-0 h-0 transition-all duration-500 ease-out bg-green-500 rounded-full group-hover:w-56 group-hover:h-56"></span>
                                <span class="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent to-gray-700"></span>
                                <span class="relative" >Update</span>
                            </button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default A_Createcertificate;
