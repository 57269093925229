import React from 'react';

function AboutH1() {
    return (
        <div className='w-full bg-white mt-3 px-4 pb-8 pt-2 md:px-8 lg:px-16 xl:px-24'>
            <div className='w-[80%] mx-auto'>
                <h1 className="text-2xl md:text-3xl lg:text-4xl font-extrabold text-[#0A2A8A] leading-tight text-center mb-6">
                    Adarsh Jankalyan Trust Pvt. Ltd.
                </h1>
                <p className='text-base font-light text-black mb-4'>
                Adarsh Jankalyan Trust goal is to develop and enhance the opportunities for the students where they can able to utilize best with their capabilities and skills. Our goal is to successfully educate and prepare individuals seeking a career in Information Technology.
                </p>
                <p className='text-base font-light text-black mb-4'>
                    Our unique way of training provides the individual with the highest level of flexibility, providing anytime anywhere learning. Adarsh Jankalyan Trust Private Limited is committed to providing individuals the training needed to transition into high tech jobs in Network Engineering, Network Administration, Technical Support, Database Administration, Programming, Office Administration, and Business Office Applications as well as other areas in the computer industry.
                </p>
                <p className='text-base font-light text-black pb-5'>
                Adarsh Jankalyan Trust Private Limited will help meet the growing demand for technical professionals and assist individuals in reaching their high-tech career goals.
                </p>
            </div>
        </div>
    );
}

export default AboutH1;
