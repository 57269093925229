import React, { useEffect, useState } from 'react';
import Http from '../../Services/Http';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

function Register() {
  const [franchise, setFranchise] = useState([]); /// franchise
  const [student, setStudent] = useState([]); ///// Student
  const [franchiseStudent, setfranchiseStudent] = useState([]);
  const [studentid1, setstudentid] = useState('')

  const [validationSchema, setValidationSchema] = useState(null);

  const [franchiserid1, setFranchiserid] = useState('');
  const [username1, setUsername] = useState('');
  const [email1, setEmail] = useState('');
  const [mobile1, setMobile] = useState('');
  const [duplicate, setDuplicate] = useState(false);

  const [mobileStudent, setMobileStudent] = useState(null);
  const [nameStudent, setNameStudent] = useState(null);

  const user = localStorage.getItem('userdata');
  const loginUser = JSON.parse(user);
  

  const navigate = useNavigate();

  // Get Franchise
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Http({
          url: `/franchise/getfranchise`,
          method: 'get',
          data: {}
        });
        const data = res.data.franchise;
        if (loginUser.role === 'director') {
          setFranchise(data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [loginUser?.role]);

  // Get Student
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Http({
          url: `/student/getstudent`,
          method: 'get',
          data: {}
        });
        const data = res?.data?.students;
        if (loginUser?.role === 'franchise') {
          setStudent(data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [loginUser?.role]);

  // Filter franchise and student
  useEffect(() => {
    if (loginUser?.role === 'director') {
      let selectedFranchise = franchise.filter(item => item?._id === franchiserid1);
      if (selectedFranchise) {
        setUsername(selectedFranchise[0]?.name);
        setEmail(selectedFranchise[0]?.email);
        setMobile(selectedFranchise[0]?.mobilenumber);
      }
    } else if (loginUser?.role === 'franchise') {
      let selectedStudent = franchiseStudent.filter(item => item?._id === studentid1);

      if (selectedStudent) {
        setMobileStudent(selectedStudent[0]?.mobile);
        setNameStudent(selectedStudent[0]?.studentname);
      }
    }
  }, [franchise, student, franchiserid1,studentid1, loginUser?.role,franchiseStudent]);

  useEffect(() => {
    const findStudent = student.filter((item)=>{
      return  item?.franchise_or_director_id?._id === loginUser?.franchiserid?._id
    });
    setfranchiseStudent(findStudent)
  }, [loginUser?.franchiserid?._id,student])
  

  // Formik setup

  const initialValues = {
    username: '',
    email: '',
    password: '',
    role: '',
    franchiserid: '',
    mobile: '',
    status: ''
  };
  useEffect(() => {
    if (loginUser?.role === 'director') {
      setValidationSchema(
        Yup.object({
          password: Yup.string().min(6, 'Must be at least 6 characters').required('Required'),
          role: Yup.string().required('Required'),
          status: Yup.string().required('Required'),
        })
      );
    } else if (loginUser?.role === 'franchise') {
      setValidationSchema(
        Yup.object({
          password: Yup.string().min(6, 'Must be at least 6 characters').required('Required'),
          role: Yup.string().required('Required'),
          status: Yup.string().required('Required'),
          email: Yup.string().required('Required').email(),
        })
      );
    }
  }, [loginUser?.role]);
  

  const handleUsernameChange = event => setUsername(event.target.value);
  const handleEmailChange = event => setEmail(event.target.value);
  const handleFranchiser_or_StudentidChange = event => setFranchiserid(event.target.value);
  const handleMobileChange = event => setMobile(event.target.value);

  const StudentidChange = event => setstudentid(event.target.value);

  

  // Student start
 

  const handleStudentNameChange = event =>   setNameStudent(event.target.value);
  const handleStudentMobileChange = event => setMobileStudent(event.target.value);

  // Student End 

  const onSubmit = async values => {
   

    if (loginUser?.role === "director" ) {
      const username = username1;
      const franchiserid = franchiserid1;
      const role = values.role;
      const email = email1;
      const password = values.password;
      const mobile = mobile1;
      const status = values.status;
      try {
        let res = await Http({
          url: `/auth/createuser`,
          method: 'post',
          data: { username, role, franchiserid, password, mobile, email, status }
        });
        const message = res?.data?.message;
        if (message === 'user_created') {
          // Add logic to update franchise  state here if necessary
          setDuplicate(false);
          navigate('/home/dashboardpage');
        } else if (message === 'duplicate') {
          setDuplicate(true);
        }
      } catch (error) {
        console.error(`Catch Error ${error}`);
        setDuplicate(true);
      }
    }
    else if (loginUser?.role === "franchise") {
      const username = nameStudent;
      const franchiserid = loginUser?.franchiserid?._id;
      const studentid = studentid1
      const role = values.role;
      const email = values.email;
      const password = values.password;
      const mobile = mobileStudent;
      const status = values.status;
      try {
        let res = await Http({
          url: `/auth/createuser`,
          method: 'post',
          data: { username, role, franchiserid,studentid, password, mobile, email, status }
        });
        const message = res?.data?.message;
        if (message === 'user_created') {
          // Add logic to update student state here if necessary
          setDuplicate(false);
          navigate('/home/dashboardpage');
        } else if (message === 'duplicate') {
          setDuplicate(true);
        }
      } catch (error) {
        console.error(`Catch Error ${error}`);
        setDuplicate(true);
      }
    }
   
  };

  return (
    <div>
      <div className="w-full mt-8 mx-auto">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form className="w-[95%] h-screen bg-white mx-auto rounded-md border border-gray-200 flex justify-between">
            <div className="w-[80%] ">
              <div className="w-full py-3 px-4 bg-gray-700 rounded-tl-md text-white mx-auto border-b">
                <h1 className="text-xl tracking-wider font-bold ">Register for Login</h1>
              </div>
              {
                loginUser?.role === "director"?
                <h1 className="text-xl tracking-wide text-gray-700 font-bold text-center mt-2">Create the franchise first Then Return here</h1>
                :
                <h1 className="text-xl tracking-wide text-gray-700 font-bold text-center mt-2">Create the Student first Then Return here</h1>
              }
              
              <div className="w-full p-4">
                <div className="w-full flex gap-4">
                  <div className="w-full md:w-[70%]">


                    {
                      loginUser?.role === "director" ?
                        (

                          <>
                            <label className="block py-2 px-2 rounded-sm tracking-wide">Franchise Name</label>
                            <select
                              type="text"
                              name="franchiserid"
                              value={franchiserid1} onChange={handleFranchiser_or_StudentidChange}
                              className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm"
                            >
                              <option value="">Select</option>
                              {
                                franchise?.map(franchiseItem => (
                                  <option key={franchiseItem?._id} value={franchiseItem?._id}>
                                    {franchiseItem?.franchisename?franchiseItem?.franchisename : "Wait loading"}
                                  </option>
                                ))
                              }
                            </select>
                            <p className="text-red-600 text-sm mt-1 ml-2">
                              <ErrorMessage name='franchiserid' />
                            </p>
                          </>

                        ) : (
                          <>
                            <label className="block py-2 px-2 rounded-sm tracking-wide">Student Name</label>
                            <select
                              type="text"
                              name="studentid1"
                              value={studentid1} onChange={StudentidChange}
                              className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm"
                            >
                              <option value="">Select</option>
                              {
                                franchiseStudent?.map(studentItem => (
                                  <option key={studentItem?._id} value={studentItem?._id}>
                                    {studentItem?.studentname}
                                  </option>
                                ))
                              }
                            </select>
                            <p className="text-red-600 text-sm mt-1 ml-2">
                              <ErrorMessage name='studentid' />
                            </p>
                          </>

                        )
                    }



                  </div>
                  <div className="w-full md:w-[30%]">
                    <label className="block py-2 px-2 rounded-sm tracking-wide"> Name</label>
                    {
                      loginUser?.role === "director" ?
                        (
                          <>
                            <input
                              type="text"
                              name="username"
                              value={username1} onChange={handleUsernameChange}
                              className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                            />
                            <p className="text-red-600 text-sm mt-1 ml-2">
                              <ErrorMessage name='username' />
                            </p>
                          </>
                        ) :
                        (
                          <>
                            <input
                              type="text"
                              name="username"
                              value={nameStudent} onChange={handleStudentNameChange}
                              className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                            />
                            <p className="text-red-600 text-sm mt-1 ml-2">
                              <ErrorMessage name='username' />
                            </p>

                          </>
                        )
                    }

                  </div>
                </div>
                <div className="w-full flex gap-4">


                  <div className='w-[48%]'>
                    <label className="block py-2 px-2 rounded-sm tracking-wide">Role</label>
                    {
                      loginUser?.role === "director" ?
                        (
                          <>
                            <Field
                              as="select"
                              name="role"

                              className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                            >
                              <option value={""}>Select Role</option>
                              <option value={"franchise"}>Franchise</option>
                            </Field>
                            <p className="text-red-600 text-sm mt-1 ml-2">
                              <ErrorMessage name='role' />
                            </p>
                          </>
                        ) : (
                          <>
                            <Field
                              as="select"
                              name="role"

                              className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                            >
                              <option value={""}>Select Role</option>
                              <option value={"student"}>Student</option>
                            </Field>
                            <p className="text-red-600 text-sm mt-1 ml-2">
                              <ErrorMessage name='role' />
                            </p>
                          </>
                        )
                    }

                  </div>
                  <div className="w-[48%]" >
                    <label className="block py-2 px-2 rounded-sm tracking-wide">Status</label>
                    {
                      loginUser?.role === "director" ?
                        (
                          <>
                            <Field
                              as="select"
                              name="status"

                              className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                            >
                              <option value="">Select</option>
                              <option value="true">Acive</option>
                              <option value="false">Inactive</option>
                            </Field>
                            <p className="text-red-600 text-sm mt-1 ml-2">
                              <ErrorMessage name='status' />
                            </p>
                          </>
                        ) : (
                          <>
                            <Field
                              as="select"
                              name="status"

                              className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                            >
                              <option value="">Select</option>
                              <option value="true">Acive</option>
                              <option value="false">Inactive</option>
                            </Field>
                            <p className="text-red-600 text-sm mt-1 ml-2">
                              <ErrorMessage name='status' />
                            </p>
                          </>
                        )
                    }

                  </div>



                </div>
                <div className="w-full flex gap-4 justify-between">
                  <div className="w-full md:w-[30%]">
                    <label className="block py-2 px-2 rounded-sm tracking-wide">Mobile Number </label>
                    {
                      loginUser?.role === "director" ?
                        (
                          <>
                            <input
                              type="number"
                              name="mobile"
                              value={mobile1} onChange={handleMobileChange}
                              className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                            />
                            <p className="text-red-600 text-sm mt-1 ml-2">
                              <ErrorMessage name='mobile' />
                            </p>
                          </>
                        ) : (
                          <>
                            <>
                              <input
                                type="number"
                                name="mobile"
                                value={mobileStudent} onChange={handleStudentMobileChange}
                                className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                              />
                              <p className="text-red-600 text-sm mt-1 ml-2">
                                <ErrorMessage name='mobile' />
                              </p>
                            </>
                          </>
                        )
                    }

                  </div>
                  <div className="w-full md:w-[30%]">
                    <label className="block py-2 px-2 rounded-sm tracking-wide">Email</label>
                    {
                      loginUser?.role === "director" ?
                        (
                          <>
                            <input
                              type="text"
                              name="email"
                              value={email1} onChange={handleEmailChange}
                              className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                            />
                            <p className="text-red-600 text-sm mt-1 ml-2">
                              <ErrorMessage name='email' />
                            </p>
                          </>
                        ) : (
                          <>
                            <Field
                              type="text"
                              name="email"
                            
                              className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                            />
                            <p className="text-red-600 text-sm mt-1 ml-2">
                              <ErrorMessage name='email' />
                            </p>
                          </>
                        )
                    }

                  </div>
                  <div className="w-full md:w-[30%]">
                    <label className="block py-2 px-2 rounded-sm tracking-wide">Password</label>
                    {
                      loginUser?.role === "director" ?
                        (
                          <>
                            <Field
                              type="text"
                              name="password"

                              className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                            />

                            <p className="text-red-600 text-sm mt-1 ml-2">
                              <ErrorMessage name='password' />
                            </p>
                          </>
                        ) : (
                          <>
                            <Field
                              type="text"
                              name="password"

                              className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                            />

                            <p className="text-red-600 text-sm mt-1 ml-2">
                              <ErrorMessage name='password' />
                            </p>
                          </>
                        )
                    }

                  </div>
                </div>
              </div>
            </div>
            <div className="w-[20%] border-l border-gray-300 my-5">
              <div className="w-full flex justify-evenly">

                <button
                  type="submit"
                  className="focus:outline-none text-white bg-[#ff0000] hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-md text-sm px-5 py-2.5 me-2 mb-2 dark:bg-[#ff0000] dark:hover:bg-red-700 dark:focus:ring-red-900"
                >
                  Submit
                </button>
              </div>
              <div>
                {
                  duplicate && <h1 className='text-red-600 text-2xl font-semibold px-4'>User Is Already Register</h1>
                }

              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  )
}

export default Register