import React from 'react'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'
import Http from '../../../Services/Http';
import MobileNavbar from '../../Components/MobileNavbar';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    number: Yup.string().required('Mobile number is required').min(10,'mobile number mimimun 10 digit').max(10,"mobile number maxmix 10 digit"),
    message: Yup.string().required('Message is required'),
});
function Student_Query() {
    const initialValues = {
        name: '',
        email: '',
        number: '',
        message: '',
    };

    const onSubmit = async (values, { resetForm }) => {
        const name = values?.name;
        const email = values?.email;
        const number = values?.number;
        const message = values?.message;
        try {
            let res = await Http({
                url:`/query/create_student_query`,
                method:"post",
                data:{name,email,number,message ,catogary:"student"},
            });
            if (res?.data?.message === "savedQuery_201") {
                resetForm();
            }
        } catch (error) {
            console.log(`server error ${error}`);
        }
      
    };
  return (
    <div>
         <div className='hidden md:block'>
                <Navbar/>
            </div>
            <div className=' md:hidden'>
                <MobileNavbar/>
            </div>
            <div className='mt-10 md:mt-28 mb-5'>
            <div className="w-[90%]  lg:w-[40%] md:w-1/2 px-4 bg-white  rounded-md flex flex-col mx-auto  md:py-2 mt-8 md:mt-0">
                        <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">Contact US</h2>
                        
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            {({ touched, errors, isSubmitting }) => (
                                <Form className=''>
                                    <div className="relative mb-4">
                                        <label htmlFor="name" className="leading-7 text-sm text-gray-600">Name</label>
                                        <Field type="text" id="name" name="name" className={`w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out ${touched.name && errors.name ? 'border-red-500' : ''}`} />
                                        <ErrorMessage name="name" component="div" className="text-red-500 text-xs mt-1" />
                                    </div>
                                    <div className="relative mb-4">
                                        <label htmlFor="email" className="leading-7 text-sm text-gray-600">Email</label>
                                        <Field type="email" id="email" name="email" className={`w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out ${touched.email && errors.email ? 'border-red-500' : ''}`} />
                                        <ErrorMessage name="email" component="div" className="text-red-500 text-xs mt-1" />
                                    </div>
                                    <div className="relative mb-4">
                                        <label htmlFor="number" className="leading-7 text-sm text-gray-600">Mobile Number</label>
                                        <Field type="number" id="number" name="number" className={`w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out ${touched.number && errors.number ? 'border-red-500' : ''}`} />
                                        <ErrorMessage name="number" component="div" className="text-red-500 text-xs mt-1" />
                                    </div>
                                    <div className="relative mb-4">
                                        <label htmlFor="message" className="leading-7 text-sm text-gray-600">Message</label>
                                        <Field as="textarea" id="message" name="message" className={`w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out ${touched.message && errors.message ? 'border-red-500' : ''}`} />
                                        <ErrorMessage name="message" component="div" className="text-red-500 text-xs mt-1" />
                                    </div>
                                    <button type="submit" disabled={isSubmitting} className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Submit</button>
                                </Form>
                            )}
                        </Formik>

                        
                    </div>
            </div>
        <Footer/>
    </div>
  )
}

export default Student_Query