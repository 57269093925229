import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Http from "../../../Services/Http";

function TrainingDetails() {
    const [course, setCourse] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await Http({
                    url: `/course/getcourse`,
                    method: "get",
                    data: {}
                });
                const data = res?.data?.course;
                setCourse(data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error("Error fetching course data:", error);
            }
        };
        fetchData();
    }, []);

    const navigateToCourse = (id) => {
        navigate(`/courses/${id}`);
    };

    return (
        <div className='bg-slate-50 w-full py-28'>
            <h1 className='text-[#16275a] text-2xl md:text-3xl lg:text-4xl text-center font-medium mb-5'>Our Training Programs</h1>
            <p className='text-center mb-14'>We Provide Training For The Following With Full Features & Facilities.</p>

            <div className='w-[90%] md:w-[70%] mx-auto md:flex justify-between'>
                {loading ? (
                    <>
                        <div className="max-w-xs w-full rounded overflow-hidden shadow-lg mb-5">
                            <div className='w-full h-48 bg-gray-200 rounded-md'></div>
                            <div className="px-6 py-4">
                                <div className="font-bold text-xl mb-2 h-5"></div>
                                <div className="text-gray-700 text-base h-14" ></div>
                            </div>
                            <div className="px-6 py-4">
                                <p className='text-[#0A2A8A] text-sm font-serif mt-5'></p>
                            </div>
                        </div>
                        <div className="max-w-xs w-full rounded overflow-hidden shadow-lg mb-5">
                            <div className='w-full h-48 bg-gray-200 rounded-md'></div>
                            <div className="px-6 py-4">
                                <div className="font-bold text-xl mb-2 h-5"></div>
                                <div className="text-gray-700 text-base h-14" ></div>
                            </div>
                            <div className="px-6 py-4">
                                <p className='text-[#0A2A8A] text-sm font-serif mt-5'></p>
                            </div>
                        </div>
                        <div className="max-w-xs w-full rounded overflow-hidden shadow-lg mb-5">
                            <div className='w-full h-48 bg-gray-200 rounded-md'></div>
                            <div className="px-6 py-4">
                                <div className="font-bold text-xl mb-2 h-5"></div>
                                <div className="text-gray-700 text-base h-14" ></div>
                            </div>
                            <div className="px-6 py-4">
                                <p className='text-[#0A2A8A] text-sm font-serif mt-5'></p>
                            </div>
                        </div>
                    </>




                ) : (
                    course.map((item, index) => (
                        <div key={index} onClick={() => navigateToCourse(item?._id)} className="max-w-xs w-full rounded overflow-hidden shadow-lg mb-5">
                            {!item?.image ? (
                                <div className='w-full h-48 bg-gray-200 rounded-md'></div>
                            ) : (
                                <img className="w-full h-48 rounded border-b-4" src={item?.image} alt="Course" />
                            )}
                            <div className="px-6 py-4">
                                <div className="font-bold text-xl mb-2">{item?.name}</div>
                                <div className="text-gray-700 text-base" dangerouslySetInnerHTML={{ __html: item?.discription.substring(0, 150) }}></div>
                            </div>
                            <div className="px-6 py-4">
                                <p className='text-[#0A2A8A] text-sm font-serif mt-5'>Read More →</p>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default TrainingDetails;
