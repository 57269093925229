import React, { useEffect, useState } from 'react'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import Http from '../../../Services/Http';
import MobileNavbar from '../../Components/MobileNavbar';

function Franchise() {
    const [Franchise, setFranchise] = useState([]);
    const [loading, setloading] = useState(true);
    useEffect(() => {
        const fechData = async () => {
            try {
                let res = await Http({
                    url: `/franchise/getfranchise`,
                    method: "get",
                    data: {}
                });
                const data = res?.data?.franchise;
                const filter = data?.filter((item) => item?.status === true);

                setFranchise(filter);
                setloading(false)
            } catch (error) {
                setloading(false)
                console.log("course page eroror", error);
            }


        };
        fechData()
    }, [])
    const mobileNumberHandler = (phoneNumber) => {
        window.open(`tel:${phoneNumber}`, '_blank')
    }
    return (
        <div>
            <div className='hidden md:block'>
                <Navbar />
            </div>
            <div className=' md:hidden'>
                <MobileNavbar />
            </div>
            <div className='w-full mx-auto mt-10 md:mt-28 sm:w-[70%]'>
                {
                    loading ?
                        <section className="text-gray-100 body-font mb-5">
                            <div className="container px-5 py-24 mx-auto flex flex-wrap cursor-auto bg-white rounded-md shadow-md border">
                                <div className="flex flex-wrap -mx-4 mt-auto mb-auto w-full sm:w-[70%] content-start sm:pr-10">
                                    <div className="w-full sm:p-4 px-2 mb-6">
                                        <div className="title-font font-extrabold text-4xl mb-2 text-gray-50"></div>
                                        <div className="leading-relaxed"></div>
                                    </div>
                                    <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                        <div className="title-font font-medium text-xl text-gray-50 whitespace-nowrap"></div>
                                        <p className="leading-relaxed">Name</p>
                                    </div>
                                    <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                        <div className="title-font font-medium text-xl text-gray-50 whitespace-nowrap"></div>
                                        <p className="leading-relaxed">Mobile</p>
                                    </div>
                                    <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                        <div className="title-font font-medium text-xl text-gray-50 whitespace-nowrap"></div>
                                        <p className="leading-relaxed">Pincode</p>
                                    </div>
                                    <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                        <div className="title-font font-medium text-xl text-gray-50 whitespace-nowrap"></div>
                                        <p className="leading-relaxed">Panchayat</p>
                                    </div>
                                </div>
                                <div className="w-full sm:w-[30%] h-64 rounded-lg overflow-hidden mt-6 sm:mt-0">
                                    <div className='w-full h-full bg-gray-200 rounded-md'></div>
                                </div>
                            </div>
                        </section>
                        :
                        Franchise?.map((item, index) => (
                            <section key={index} className="text-gray-100 body-font mb-5">
                                <div className="container px-5 py-24 mx-auto   cursor-auto bg-[#0a2a8a] rounded-md shadow-md border">
                                    <div className="w-full sm:w-[30%] h-64 rounded-lg overflow-hidden mt-6 sm:mt-0">
                                        {
                                            !item?.image ? <div className='w-full h-full bg-gray-200 rounded-md'></div> : <img className="object-cover object-center w-full h-full" src={item?.image} alt="stats" />
                                        }
                                    </div>
                                    <div className="flex flex-wrap -mx-4 mt-auto mb-auto w-full content-start sm:pr-10">
                                        <div className="w-full sm:p-4 px-2 mb-6">
                                            <h1 className="title-font font-extrabold text-2xl md:text-4xl mb-2 text-gray-50">{item?.franchisename}</h1>
                                            <div className="leading-relaxed">{item?.fulladdress}</div>
                                        </div>
                                        <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                        <p className="leading-relaxed">Name</p>
                                            <h2 className="title-font font-medium text-xl text-gray-50 whitespace-nowrap">{item?.name}</h2>
                                            
                                        </div>
                                        <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                        <p className="leading-relaxed">Mobile</p>
                                            <h2 onClick={() => mobileNumberHandler(item?.mobilenumber)} className="title-font font-medium text-xl text-gray-50 whitespace-nowrap">{item?.mobilenumber}</h2>
                                          
                                        </div>
                                        <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                        <p className="leading-relaxed">Pincode</p>
                                            <h2 className="title-font font-medium text-xl text-gray-50 whitespace-nowrap">{item?.pincode}</h2>
                                          
                                        </div>
                                        <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                        <p className="leading-relaxed">Panchayat</p>
                                            <h2 className="title-font font-medium text-xl text-gray-50 whitespace-nowrap">{item?.panchayat}</h2>
                                            
                                        </div>
                                    </div>

                                </div>
                            </section>
                        ))
                }
            </div>

            <Footer />
        </div>
    )
}

export default Franchise