import React, { useEffect, useState } from 'react'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import Http from '../../../Services/Http'
import { BsCurrencyRupee } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import MobileNavbar from '../../Components/MobileNavbar';


function Courses() {
    const [course, setcourse] = useState([]);
    const [loading, setloading] = useState(true);
    const navigate = useNavigate()

    useEffect(() => {
        const fechData = async () => {
            try {
                let res = await Http({
                    url: `/course/getcourse`,
                    method: "get",
                    data: {}
                });
                const data = res?.data?.course;
                setcourse(data);
                setloading(false)
            } catch (error) {
                setloading(false)
                console.log("course page eroror", error);
            }


        };
        fechData()
    }, [])
    const ThisCourse = (id)=>{
        navigate(`/courses/${id}`)
    }

    return (
        <>
             <div className='hidden md:block'>
                <Navbar/>
            </div>
            <div className=' md:hidden'>
                <MobileNavbar/>
            </div>
            <div className='w-[90%] md:w-[70%] mx-auto cursor-pointer mt-10  md:mt-32 mb-10 grid grid-cols-1 sm:grid-cols-3 gap-5'>
                {
                    loading ?
                        <>
                            <div className="max-w-xs rounded overflow-hidden shadow-lg bg-white">
                                <div className='w-full h-48'>
                                    <div className='w-full h-48 bg-gray-200 rounded-md'>

                                    </div>

                                </div>

                                <div className="px-2 py-4">
                                    <div className="font-bold text-xl mb-2 h-6 bg-gray-200 rounded-md"></div>
                                    <p className="text-gray-700 text-base h-8 bg-gray-200 rounded-md"></p>
                                </div>
                            </div>
                            <div className="max-w-xs rounded overflow-hidden shadow-lg bg-white">
                                <div className='w-full h-48'>
                                    <div className='w-full h-48 bg-gray-200 rounded-md'>

                                    </div>

                                </div>

                                <div className="px-2 py-4">
                                    <div className="font-bold text-xl mb-2 h-6 bg-gray-200 rounded-md"></div>
                                    <p className="text-gray-700 text-base h-8 bg-gray-200 rounded-md"></p>
                                </div>
                            </div>
                            <div className="max-w-xs rounded overflow-hidden shadow-lg bg-white">
                                <div className='w-full h-48'>
                                    <div className='w-full h-48 bg-gray-200 rounded-md'>

                                    </div>

                                </div>

                                <div className="px-2 py-4">
                                    <div className="font-bold text-xl mb-2 h-6 bg-gray-200 rounded-md"></div>
                                    <p className="text-gray-700 text-base h-8 bg-gray-200 rounded-md"></p>
                                </div>
                            </div>
                            <div className="max-w-xs rounded overflow-hidden shadow-lg bg-white">
                                <div className='w-full h-48'>
                                    <div className='w-full h-48 bg-gray-200 rounded-md'>

                                    </div>

                                </div>

                                <div className="px-2 py-4">
                                    <div className="font-bold text-xl mb-2 h-6 bg-gray-200 rounded-md"></div>
                                    <p className="text-gray-700 text-base h-8 bg-gray-200 rounded-md"></p>
                                </div>
                            </div>
                            <div className="max-w-xs rounded overflow-hidden shadow-lg bg-white">
                                <div className='w-full h-48'>
                                    <div className='w-full h-48 bg-gray-200 rounded-md'>

                                    </div>

                                </div>

                                <div className="px-2 py-4">
                                    <div className="font-bold text-xl mb-2 h-6 bg-gray-200 rounded-md"></div>
                                    <p className="text-gray-700 text-base h-8 bg-gray-200 rounded-md"></p>
                                </div>
                            </div>
                            <div className="max-w-xs rounded overflow-hidden shadow-lg bg-white">
                                <div className='w-full h-48'>
                                    <div className='w-full h-48 bg-gray-200 rounded-md'>

                                    </div>

                                </div>

                                <div className="px-2 py-4">
                                    <div className="font-bold text-xl mb-2 h-6 bg-gray-200 rounded-md"></div>
                                    <p className="text-gray-700 text-base h-8 bg-gray-200 rounded-md"></p>
                                </div>
                            </div>
                        </>
                        :

                        course.map((item, index) => (

                            <div key={index} onClick={()=>ThisCourse(item?._id)} className="max-w-xs bg-[#0a2a8a] border   rounded  overflow-hidden shadow-lg ">
                                <div className='w-full h-52'>
                                    {
                                        !item?.image ?
                                            <div className='w-full h-48 bg-gray-200 rounded-md'>

                                            </div> :
                                            <img className="w-full h-full rounded border-b-4" src={item?.image} alt="Course" />
                                    }


                                </div>

                                <div className="px-6 py-4">
                                    <p className='font-bold text-xl mb-2 flex text-gray-100'><BsCurrencyRupee className='mt-1' size={20} />{item?.fees}</p>
                                    <h1 className="font-bold text-xl mb-2 text-gray-200">{item?.name}</h1>

                                </div>
                            </div>

                        ))


                }
            </div>
            <Footer />
        </>
    )
}

export default Courses