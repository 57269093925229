import React, { useEffect, useState } from 'react';
import Http from '../../../Services/Http';

function Institution() {
    const [franchise, setFranchise] = useState([]);
    const user = localStorage.getItem('userdata');
    const loginUser = JSON.parse(user);
    const studentFranchise = loginUser?.franchiserid?._id 
    // const [loading, setloading] = useState(true);

 
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await Http({
            url: `/franchise/getnstitution`,
            method: "post",
            data: {studentFranchise}
          });
        //   setloading(false)
          setFranchise(res?.data?.franchise);
        } catch (error) {
          console.error("Error fetching data:", error);
        //   setloading(false)
        }
      };
      fetchData();
    }, [studentFranchise]);
    return (
        <>
            <section className="text-gray-600 body-font relative">
                <div className="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
                    <div className="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
                        <iframe
                            width="100%"
                            height="100%"
                            className="absolute inset-0"
                            frameBorder={0}
                            title="map"
                            marginHeight={0}
                            marginWidth={0}
                            scrolling="no"
                            src="https://maps.google.com/maps?width=100%&height=600&hl=en&q=%C4%B0zmir+(My%20Business%20Name)&ie=UTF8&t=&z=14&iwloc=B&output=embed"
                            style={{ filter: "grayscale(1) contrast(1.2) opacity(0.4)" }}
                        />
                        <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
                            <div className="lg:w-1/2 px-6">
                                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                                    ADDRESS
                                </h2>
                                <p className="mt-1">
                                {`State ${franchise[0]?.state},District ${franchise[0]?.district},Village ${franchise[0]?.village}`}
                                </p>
                            </div>
                            <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                                
                                
                                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                                    PHONE
                                </h2>
                                <p className="leading-relaxed">+91 {franchise[0]?.mobilenumber}</p>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/3 md:w-1/2 p-8 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
                   
                      
                        <div className="relative mb-4">
                            <label htmlFor="name" className="leading-7 text-sm text-gray-600">
                                Name
                            </label>
                            <h1>{franchise[0]?.name}</h1>
                        </div>
                        <div className="relative mb-4">
                            <label htmlFor="email" className="leading-7 text-sm text-gray-600">
                            Institution Name
                            </label>
                            <h1>{franchise[0]?.franchisename}</h1>
                        </div>
                        <div className="relative mb-4">
                           
                          
                        </div>
                       <div>
                            <div className='w-full h-60 bg-gray-200 rounded-md '>

                            </div>
                       </div>
                        
                    </div>
                </div>
            </section>

        </>
    );
}

export default Institution;
