import React, { useContext } from 'react';
import { BsShop } from "react-icons/bs";
import { MdSpaceDashboard } from "react-icons/md";
import { PiStudentFill } from "react-icons/pi";
import { Link, NavLink } from 'react-router-dom';
import { SiCoursera } from "react-icons/si";
import { TbFileCertificate } from "react-icons/tb";
import { IoLogIn } from "react-icons/io5";
import { MyContext } from '../../Context/MyContextProvider';
import { DiJqueryUiLogo } from "react-icons/di";
import logo from '../../Assets/logo512.png.png'


function Sidebar() {
    const iconSize = 30;
    const {setStudentNavbar,setFalseRegister, setFranchisNavbar,setCourse } = useContext(MyContext);
    const user = localStorage.getItem('userdata');
    const loginUser = JSON.parse(user);



    return (
        <div className="w-auto text-gray-200">
            <Link to={'/'} className="w-auto flex mt-2 mx-4 py- pl-4 pr-12  rounded-md items-center">
                <img src={logo} alt='logo'
                    className="text-white w-10 h-10 font-semibold" />
                <h1 className="ml-2 text-lg font-semibold text-gray-50">Asktust</h1>
            </Link>
            {
                loginUser?.role === "director" &&
                <>
                    {/* Dashboard */}
                    <NavLink to={'dashboardpage'} className="w-auto flex mt-3 mx-4 py-1 pl-4 pr-12  rounded-md items-center">
                        <div style={{ width: iconSize, height: iconSize }}>
                            <MdSpaceDashboard size={iconSize} className="text-white font-semibold" />
                        </div>
                        <h1 className="ml-2 text-lg font-semibold text-gray-50">Dashborad</h1>
                    </NavLink>

                    {/* Director */}
                    <NavLink to={'certificatepage'} className="w-auto flex mt-3 mx-4 py-1 pl-4 pr-12  rounded-md items-center">
                        <div style={{ width: iconSize, height: iconSize }}>
                            <TbFileCertificate size={iconSize} className="text-white font-semibold" />
                        </div>
                        <h1 className="ml-2 text-lg font-semibold text-gray-50">Certificate</h1>
                    </NavLink>
                    {/* Course */}
                    <NavLink to={'coursePage'} onClick={()=>setCourse(false)} className="w-auto flex mt-3 mx-4 py-1 pl-4 pr-12  rounded-md items-center">
                        <div style={{ width: iconSize, height: iconSize }}>
                            <SiCoursera size={iconSize} className="text-white font-semibold" />
                        </div>
                        <h1 className="ml-2 text-lg font-semibold text-gray-50">Course</h1>
                    </NavLink>

                    {/* Franchise */}
                    <NavLink to={'franchisepage'} onClick={()=>setFranchisNavbar(false)} className="w-auto flex mt-3 mx-4 py-1 pl-4 pr-12  rounded-md items-center">
                        <div style={{ width: iconSize, height: iconSize }}>
                            <BsShop size={iconSize} className="text-white font-semibold" />
                        </div>
                        <h1 className="ml-2 text-lg font-semibold text-gray-50">Franchise</h1>
                    </NavLink>

                    {/* Student */}
                    <NavLink to={'studentpage'} onClick={()=>setStudentNavbar(false)} className="w-auto flex mt-3 mx-4 py-1 pl-4 pr-12  rounded-md items-center">
                        <div style={{ width: iconSize, height: iconSize }}>
                            <PiStudentFill size={iconSize} className="text-white font-semibold" />
                        </div>
                        <h1 className="ml-2 text-lg font-semibold text-gray-50">Student</h1>
                    </NavLink>
                    {/* LoginAsses */}
                    <NavLink to={'registerpage'} onClick={()=>setFalseRegister(false)} className="w-auto flex mt-3 mx-4 py-1 pl-4 pr-12  rounded-md items-center">
                        <div style={{ width: iconSize, height: iconSize }}>
                            <IoLogIn size={iconSize} className="text-white font-semibold" />
                        </div>
                        <h1 className="ml-2 text-lg font-semibold text-gray-50">LoginAsses</h1>
                    </NavLink>
                    <NavLink to={'studentquery'} onClick={()=>setFalseRegister(false)} className="w-auto flex mt-3 mx-4 py-1 pl-4 pr-12  rounded-md items-center">
                        <div style={{ width: iconSize, height: iconSize }}>
                            <DiJqueryUiLogo size={iconSize} className="text-white font-semibold" />
                        </div>
                        <h1 className="ml-2 text-lg font-semibold text-gray-50">Student Query</h1>
                    </NavLink>
                    {/* <NavLink to={'franchisequery'} onClick={()=>setFalseRegister(false)} className="w-auto flex mt-3 mx-4 py-1 pl-4 pr-12  rounded-md items-center">
                        <div style={{ width: iconSize, height: iconSize }}>
                            <DiJqueryUiLogo size={iconSize} className="text-white font-semibold" />
                        </div>
                        <h1 className="ml-2 text-lg font-semibold text-gray-50">Franchise Query</h1>
                    </NavLink> */}

                </>
            }
            {
                loginUser?.role === "franchise" &&
                <>
                    {/* Dashboard */}
                    <NavLink to={'dashboardpage'} className="w-auto flex mt-3 mx-4 py-1 pl-4 pr-12  rounded-md items-center">
                        <div style={{ width: iconSize, height: iconSize }}>
                            <MdSpaceDashboard size={iconSize} className="text-white font-semibold" />
                        </div>
                        <h1 className="ml-2 text-lg font-semibold text-gray-50">Dashborad</h1>
                    </NavLink>

                    {/* Director */}
                    <NavLink to={'certificatepage'} className="w-auto flex mt-3 mx-4 py-1 pl-4 pr-12  rounded-md items-center">
                        <div style={{ width: iconSize, height: iconSize }}>
                            <TbFileCertificate size={iconSize} className="text-white font-semibold" />
                        </div>
                        <h1 className="ml-2 text-lg font-semibold text-gray-50">Certificate</h1>
                    </NavLink>
                    {/* Course */}
                    <NavLink to={'coursePage'} onClick={()=>setCourse(false)} className="w-auto flex mt-3 mx-4 py-1 pl-4 pr-12  rounded-md items-center">
                        <div style={{ width: iconSize, height: iconSize }}>
                            <SiCoursera size={iconSize} className="text-white font-semibold" />
                        </div>
                        <h1 className="ml-2 text-lg font-semibold text-gray-50">Course</h1>
                    </NavLink>

                    {/* Franchise */}
                    <NavLink to={'franchisepage'} onClick={()=>setFranchisNavbar(false)} className="w-auto flex mt-3 mx-4 py-1 pl-4 pr-12  rounded-md items-center">
                        <div style={{ width: iconSize, height: iconSize }}>
                            <BsShop size={iconSize} className="text-white font-semibold" />
                        </div>
                        <h1 className="ml-2 text-lg font-semibold text-gray-50">Franchise</h1>
                    </NavLink>

                    {/* Student */}
                    <NavLink to={'studentpage'} onClick={()=>setStudentNavbar(false)} className="w-auto flex mt-3 mx-4 py-1 pl-4 pr-12  rounded-md items-center">
                        <div style={{ width: iconSize, height: iconSize }}>
                            <PiStudentFill size={iconSize} className="text-white font-semibold" />
                        </div>
                        <h1 className="ml-2 text-lg font-semibold text-gray-50">Student</h1>
                    </NavLink>
                    {/* LoginAsses */}
                    <NavLink to={'registerpage'} onClick={()=>setFalseRegister(false)} className="w-auto flex mt-3 mx-4 py-1 pl-4 pr-12  rounded-md items-center">
                        <div style={{ width: iconSize, height: iconSize }}>
                            <IoLogIn size={iconSize} className="text-white font-semibold" />
                        </div>
                        <h1 className="ml-2 text-lg font-semibold text-gray-50">LoginAsses</h1>
                    </NavLink>
                </>
            }

            {
                loginUser?.role === "student" &&
                <>
                    {/* Dashboard */}
                    <NavLink to={'dashboardpage'} className="w-auto flex mt-3 mx-4 py-1 pl-4 pr-12  rounded-md items-center">
                        <div style={{ width: iconSize, height: iconSize }}>
                            <MdSpaceDashboard size={iconSize} className="text-white font-semibold" />
                        </div>
                        <h1 className="ml-2 text-lg font-semibold text-gray-50">Dashborad</h1>
                    </NavLink>

                    {/* Director */}
                    {/* <NavLink to={'certificatepage'} className="w-auto flex mt-3 mx-4 py-1 pl-4 pr-12  rounded-md items-center">
                        <div style={{ width: iconSize, height: iconSize }}>
                            <TbFileCertificate size={iconSize} className="text-white font-semibold" />
                        </div>
                        <h1 className="ml-2 text-lg font-semibold text-gray-50">Certificate</h1>
                    </NavLink> */}
                    {/* Course */}
                    <NavLink to={'coursePage'} className="w-auto flex mt-3 mx-4 py-1 pl-4 pr-12  rounded-md items-center">
                        <div style={{ width: iconSize, height: iconSize }}>
                            <SiCoursera size={iconSize} className="text-white font-semibold" />
                        </div>
                        <h1 className="ml-2 text-lg font-semibold text-gray-50">Course</h1>
                    </NavLink>
                    {/* institution only for student */}
                    <NavLink to={'institution'} className="w-auto flex mt-3 mx-4 py-1 pl-4 pr-12  rounded-md items-center">
                        <div style={{ width: iconSize, height: iconSize }}>
                            <BsShop size={iconSize} className="text-white font-semibold" />
                        </div>
                        <h1 className="ml-2 text-lg font-semibold text-gray-50">Institution</h1>
                    </NavLink>

                    {/* Student */}
                    <NavLink to={'studentpage'} onClick={()=>setStudentNavbar(false)} className="w-auto flex mt-3 mx-4 py-1 pl-4 pr-12  rounded-md items-center">
                        <div style={{ width: iconSize, height: iconSize }}>
                            <PiStudentFill size={iconSize} className="text-white font-semibold" />
                        </div>
                        <h1 className="ml-2 text-lg font-semibold text-gray-50">Student</h1>
                    </NavLink>

                </>
            }

        </div>
    );
}

export default Sidebar;
