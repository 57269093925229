import React from 'react'

function Mission() {
    return (
        <div className='Mission w-full h-full py-24'>
            <h1 className='text-[#0A2A8A] text-sm text-center font-medium mb-5'>Our Mission, Vision Statements</h1>
            <h1 className='text-[#16275a] text-3xl md:text-4xl lg:text-5xl font-medium text-center mb-20'>Our Mission & Vision</h1>
            <div className='w-[70%] mx-auto grid gap-24 grid-cols-1 md:grid-cols-1  lg:grid-cols-2'>
                <div>
                    <h1 className='text-[#0A2A8A] text-2xl font-medium mb-2'>Mission</h1>
                    <p className=' text-[15px] text-gray-800 tracking-wider leading-7'>Adarsh Jankalyan Trust’s mission is to develop and enhance the opportunities for the students where they can able to utilize best with their capabilities and skills. Our goal is to successfully educate and prepare individuals seeking a career in Information Technology. Our unique way of training provides the individual with the highest level of flexibility, providing anytime anywhere learning.</p>
                    <p className='text-[#0A2A8A] text-sm font-serif mt-5'>Read More →</p>
                </div>
                <div>
                    <h1 className='text-[#0A2A8A] text-2xl font-medium mb-2 '>Vision</h1>
                    <p className=' text-[15px] text-gray-800 tracking-wider leading-7	'>Adarsh Jankalyan Trust contributes significantly towards enrichment of higher quality talent of skilled developed human resources with international bench mark. The vision is to provide professional education merging corporate culture globally to the youth through technology resourcing and knowledge consulting with emerging technologies.</p>
                    <p className='text-[#0A2A8A] text-sm font-serif mt-5'>Read More →</p>
                </div>
            </div>
        </div>
    )
}

export default Mission