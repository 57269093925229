import React, { useContext } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Http from '../../../Services/Http';
import { MyContext } from '../../../Context/MyContextProvider';
import { DateTime } from 'date-time-helper-x';

function UpdateStudent() {
    const { setStudentToggle } = useContext(MyContext);
    const studentItemData = JSON.parse(localStorage.getItem('studentItemData'));
    const initialValues = {
        studentname: `${studentItemData.studentname}`,
        fathername: `${studentItemData.fathername}`,
        address: `${studentItemData.address}`,
        mothername: `${studentItemData.mothername}`,
        qualification: `${studentItemData.qualification}`,
        dateofbirth: `${studentItemData?.dateofbirth}`,
        aadharno: `${studentItemData.aadharno}`,
        gender: `${studentItemData.gender}`,
        status: `${studentItemData.status}`,
        mobile: `${studentItemData.mobile}`,
    };


    const validationSchema = Yup.object({
        studentname: Yup.string().min(3, 'Must be at least 3 characters').required('Required'),
        fathername: Yup.string().min(3, 'Must be at least 3 characters').required('Required'),
        address: Yup.string().min(20, 'Must be at least 20 characters').required('Required'),
        mothername: Yup.string().min(3, 'Must be at least 3 characters').required('Required'),
        qualification: Yup.string().required('Required'),
        dateofbirth: Yup.date().required('Required'),
        aadharno: Yup.string().required('Required').matches(/^\d{12}$/, 'Aadhar number must be exactly 12 digits'),
        gender: Yup.string().required('Required'),
        status: Yup.string().required('Required'),
        mobile: Yup.string().required('Required').matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits'),
    });

    const onSubmit = async (values) => {
        const studentname = values?.studentname;
        const fathername = values?.fathername;
        const address = values?.address;
        const mothername = values?.mothername;
        const qualification = values?.qualification;
        const dateofbirth = values?.dateofbirth;
        const aadharno = values?.aadharno;
        const gender = values?.gender;
        const status = values?.status;
        const mobile = values?.mobile;
        try {
            const res = await Http({
                url: `/student/updatetudent`,
                method: "patch",
                data: {studentname,fathername,address,mothername,qualification,dateofbirth,aadharno,gender,status,mobile,_id:studentItemData?._id}
            });
            if (res.data.message === "Student updated successfully") {
                setTimeout(() => {
                    setStudentToggle(false)
                }, 500);
            }
        } catch (error) {
            console.log(`Server Catch Error ${error}`);
        }
    };

    const handleClose = () => {
        setStudentToggle(false);
    };

    return (
        <div className='w-full top-0 fixed bg-black bg-opacity-50 z-50 inset-0'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                
               {formikProps => (
                <Form className="w-full mt-8 mx-auto">
                    <div className="w-[80%] h-screen bg-white mx-auto rounded-md border border-gray-200 flex justify-between">
                        <div className="w-[80%] ">
                            <div className="w-full py-3 px-4 bg-gray-700 rounded-tl-md text-white mx-auto border-b">
                                <h1 className="text-xl tracking-wider font-bold ">Student Registration</h1>
                            </div>
                            <div className="w-full p-4">
                                <div className="w-full flex gap-4">
                                    <div className="w-full md:w-[70%]">
                                        <label className="block py-2 px-2 rounded-sm tracking-wide">Student name </label>
                                        <Field
                                            type="text"
                                            name="studentname"

                                            className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        />
                                        <p className="text-red-600 text-sm mt-1 ml-2">
                                            <ErrorMessage name="studentname" component="p" />
                                        </p>
                                    </div>
                                    <div className="w-full md:w-[30%]">
                                        <label className="block py-2 px-2 rounded-sm tracking-wide">Father Name</label>
                                        <Field
                                            type="text"
                                            name="fathername"

                                            className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        />
                                        <p className="text-red-600 text-sm mt-1 ml-2">
                                            <ErrorMessage name="fathername" component="p" />
                                        </p>
                                    </div>
                                </div>
                                <div className="w-full flex gap-4">
                                    <div className="w-full md:w-[60%]">
                                        <label className="block py-2 px-2 rounded-sm tracking-wide">Adress</label>
                                        <Field
                                            as="textarea"
                                            name="address"

                                            className="block w-full h-28 border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        ></Field>
                                        <p className="text-red-600 text-sm mt-1 ml-2">
                                            <ErrorMessage name="address" component="p" />
                                        </p>
                                    </div>
                                    <div className="w-full md:w-[40%]">
                                        <div>
                                            <label className="block py-2 px-2 rounded-sm tracking-wide">Mother Name</label>
                                            <Field
                                                type="text"
                                                name="mothername"

                                                className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                            />
                                            <p className="text-red-600 text-sm mt-1 ml-2">
                                                <ErrorMessage name="mothername" component="p" />
                                            </p>
                                        </div>
                                        <div>
                                            <label className="block py-2 px-2 rounded-sm tracking-wide">Qualification</label>
                                            <Field
                                                type="text"
                                                name="qualification"

                                                className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                            />
                                            <p className="text-red-600 text-sm mt-1 ml-2">
                                                <ErrorMessage name="qualification" component="p" />
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className="w-full flex gap-4 justify-between">
                                    <div className="w-full md:w-[30%]">
                                        <label className="block py-2 px-2 rounded-sm tracking-wide">Date Of  Birth </label>
                                        <Field
                                            type="date"
                                            name="dateofbirth"

                                            className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        />
                                        <p className="text-red-600 text-sm mt-1 ml-2">
                                            <ErrorMessage name="dateofbirth" component="p" />
                                        </p>
                                        <h1 className='ml-2'>{DateTime(studentItemData?.dateofbirth ,"DDMMYYYY")}</h1>
                                    </div>
                                    <div className="w-full md:w-[30%]">
                                        <label className="block py-2 px-2 rounded-sm tracking-wide">Aadhar No</label>
                                        <Field
                                            type="number"
                                            name="aadharno"

                                            className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        />
                                        <p className="text-red-600 text-sm mt-1 ml-2">
                                            <ErrorMessage name="aadharno" component="p" />
                                        </p>
                                    </div>
                                    <div className="w-full md:w-[30%]">
                                        <label className="block py-2 px-2 rounded-sm tracking-wide">Male/Female</label>
                                        <Field
                                            as="select"

                                            name="gender"

                                            className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        >
                                            <option value="">Select</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </Field>
                                        <p className="text-red-600 text-sm mt-1 ml-2">
                                            <ErrorMessage name="gender" component="p" />
                                        </p>
                                    </div>
                                    <div className="w-full md:w-[30%]">
                                        <label className="block py-2 px-2 rounded-sm tracking-wide">Status</label>
                                        <Field

                                            name="status"
                                            as="select"
                                            className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        >
                                            <option value="">Select</option>
                                            <option value="true">Study Running</option>
                                            <option value="false">Study Complate</option>
                                        </Field>
                                        <p className="text-red-600 text-sm mt-1 ml-2">
                                            <ErrorMessage name="status" component="p" />
                                        </p>
                                    </div>
                                </div>
                                <div className="w-full flex gap-4 justify-between">
                                    <div className="w-full md:w-[50%]">
                                        <label className="block py-2 px-2 rounded-sm tracking-wide">Mobile No</label>
                                        <Field
                                            type="number"
                                            name="mobile"

                                            className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        />
                                        <p className="text-red-600 text-sm mt-1 ml-2">
                                            <ErrorMessage name="mobile" component="p" />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-[20%] border-l border-gray-300 my-5 relative">

                            <div className="w-full flex justify-evenly">
                                <div onClick={handleClose} className='absolute top-0 right-2 cursor-pointer'>
                                    <IoCloseSharp size={30} />
                                </div>
                                <button
                                    type="submit"
                                    className="focus:outline-none text-white bg-[#ff0000] hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-md text-sm px-5 py-2.5 me-2 mb-2 dark:bg-[#ff0000] dark:hover:bg-red-700 dark:focus:ring-red-900"
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
                )}
            </Formik></div>
    )
}

export default UpdateStudent