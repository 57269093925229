import React, { useContext, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Http from '../../../Services/Http';
import { MyContext } from '../../../Context/MyContextProvider';


function CreateFranchise() {
    const { setFranchisNavbar } = useContext(MyContext);
    const [image, setimage] = useState('')

    const initialValues = {
        name: '',
        // franchisename: '',
        fulladdress: '',
        mobilenumber: '',
        email: '',
        state: '',
        district: '',
        block: '',
        panchayat: '',
        village: '',
        aadhaarnumber: '',
        pincode: '',
        franchisename: ''
    };

    const validationSchema = Yup.object({
        name: Yup.string()

            .required('Required'),
        franchisename: Yup.string()

            .required('Required'),
        fulladdress: Yup.string()

            .required('Required'),
        mobilenumber: Yup.string()
            .min(10, 'Must be at least 10 characters')
            .max(10, 'Max be at least 10 characters')
            .required('Required'),
        email: Yup.string()
            .email('Email not correct')
            .required('Required'),
        state: Yup.string()

            .required('Required'),
        district: Yup.string()

            .required('Required'),
        block: Yup.string()

            .required('Required'),
        panchayat: Yup.string()

            .required('Required'),
        village: Yup.string()

            .required('Required'),
        aadhaarnumber: Yup.string()
            .min(12, 'Must be at least 12 characters')
            .max(12, 'Max be at least 12 characters')
            .required('Required'),
        pincode: Yup.string()
            .min(6, 'Must be at least 6 characters')
            .max(6, 'Max be at least 6 characters')
            .required('Required')
    });

    const onSubmit = async (values) => {
        const name = values.name
        const franchisename = values.franchisename;
        const fulladdress = values.fulladdress
        const mobilenumber = values.mobilenumber
        const email = values.email
        const state = values.state
        const district = values.district
        const block = values.block
        const panchayat = values.panchayat
        const village = values.village
        const aadhaarnumber = values.aadhaarnumber
        const pincode = values.pincode
        try {
            let res = await Http({
                url: `/franchise/createfranchise`,
                method: 'post',
                data: { name, franchisename, pincode, image, fulladdress, mobilenumber, email, state, district, block, panchayat, village, aadhaarnumber }
            });

            const message = res.data.message;
            if (message === 'franchiseSaved_200') {
                setFranchisNavbar(false)
            }
        } catch (error) {
            console.error(`Catch Error ${error}`);
        }
    };
    const handleImageUpload = (event) => {
        const imageFile = event.target.files[0];
        if (imageFile) {
            const reader = new FileReader();
            reader.onload = () => {
                const imageData = reader.result;
                // Do something with the imageData
                setimage(imageData)
            };
            reader.readAsDataURL(imageFile);
        }
    }
    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                <Form class="w-full mt-8 mx-auto">
                    <div class="w-[95%] h-screen bg-white mx-auto rounded-md border border-gray-200 flex justify-between">
                        <div class="w-[80%] ">
                            <div class="w-full py-3 px-4 bg-gray-700 rounded-tl-md text-white mx-auto border-b">
                                <h1 class="text-xl tracking-wider font-bold ">Franchise Registration</h1>
                            </div>

                            <div class="w-full p-4">
                                <div class="w-full flex gap-4">
                                    <div class="w-full md:w-[30%]">
                                        <label class="block py-2 px-2 rounded-sm tracking-wide">Name </label>
                                        <Field
                                            type="text"
                                            name="name"
                                            placeholder="Name"
                                            class="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        />
                                        <p class="text-red-600 text-sm mt-1 ml-2">
                                            <p class="text-red-600 text-sm mt-1 ml-2">
                                                <ErrorMessage name='name' />
                                            </p>
                                        </p>
                                    </div>
                                    <div className='w-full md:w-[20%] p-4 ml-5 '>
                                        <input type='file' accept="image/*" onChange={handleImageUpload} />
                                    </div>
                                    <div class="w-full md:w-[40%]">
                                        <label class="block py-2 px-2 rounded-sm tracking-wide">Franchise Name</label>
                                        <Field
                                            type="text"
                                            name="franchisename"
                                            placeholder='Enter Your Franchise Name'
                                            
                                            class="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        />

                                    </div>
                                    <div class="w-full md:w-[20%]">
                                        <label class="block py-2 px-2 rounded-sm tracking-wide">Pincode</label>
                                        <Field
                                            type="number"
                                            name="pincode"
                                            placeholder='pincode'

                                            class="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        />

                                    </div>
                                </div>
                                <div class="w-full flex gap-4">
                                    <div class="w-full md:w-[60%]">
                                        <label class="block py-2 px-2 rounded-sm tracking-wide">Full Adress</label>
                                        <Field
                                            as="textarea"
                                            name="fulladdress"
                                            placeholder="full address"
                                            class="block w-full h-28 border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        ></Field>
                                        <p class="text-red-600 text-sm mt-1 ml-2">
                                            <ErrorMessage name='fulladdress' />
                                        </p>
                                    </div>
                                    <div class="w-full md:w-[40%]">
                                        <div>
                                            <label class="block py-2 px-2 rounded-sm tracking-wide">Mobile Number</label>
                                            <Field
                                                type="number"
                                                name="mobilenumber"
                                                placeholder="Mobile Number"
                                                class="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                            />
                                            <p class="text-red-600 text-sm mt-1 ml-2">
                                                <p class="text-red-600 text-sm mt-1 ml-2">
                                                    <ErrorMessage name='mobilenumber' />
                                                </p>
                                            </p>
                                        </div>
                                        <div>
                                            <label class="block py-2 px-2 rounded-sm tracking-wide">Email</label>
                                            <Field
                                                type="text"
                                                name="email"
                                                placeholder="Email"
                                                class="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                            />
                                            <p class="text-red-600 text-sm mt-1 ml-2">
                                                <p class="text-red-600 text-sm mt-1 ml-2">
                                                    <ErrorMessage name='email' />
                                                </p>
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div class="w-full flex gap-4 justify-between">
                                    <div class="w-full md:w-[30%]">
                                        <label class="block py-2 px-2 rounded-sm tracking-wide">State</label>
                                        <Field
                                            as="select"
                                            name="state"

                                            placeholder="State"
                                            class="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        >
                                            <option value="">Select State</option>
                                            <option value="andhrapradesh">Andhra Pradesh</option>
                                            <option value="arunachalpradesh">Arunachal Pradesh</option>
                                            <option value="assam">Assam</option>
                                            <option value="bihar">Bihar</option>
                                            <option value="chhattisgarh">Chhattisgarh</option>
                                            <option value="goa">Goa</option>
                                            <option value="gujarat">Gujarat</option>
                                            <option value="haryana">Haryana</option>
                                            <option value="himachalpradesh">Himachal Pradesh</option>
                                            <option value="jammuandkashmir">Jammu and Kashmir</option>
                                            <option value="jharkhand">Jharkhand</option>
                                            <option value="karnataka">Karnataka</option>
                                            <option value="kerala">Kerala</option>
                                            <option value="madhyapradesh">Madhya Pradesh</option>
                                            <option value="maharashtra">Maharashtra</option>
                                            <option value="manipur">Manipur</option>
                                            <option value="meghalaya">Meghalaya</option>
                                            <option value="mizoram">Mizoram</option>
                                            <option value="nagaland">Nagaland</option>
                                            <option value="odisha">Odisha</option>
                                            <option value="punjab">Punjab</option>
                                            <option value="rajasthan">Rajasthan</option>
                                            <option value="sikkim">Sikkim</option>
                                            <option value="tamilnadu">Tamil Nadu</option>
                                            <option value="telangana">Telangana</option>
                                            <option value="tripura">Tripura</option>
                                            <option value="uttarpradesh">Uttar Pradesh</option>
                                            <option value="uttarakhand">Uttarakhand</option>
                                            <option value="westbengal">West Bengal</option>


                                        </Field>
                                        <p class="text-red-600 text-sm mt-1 ml-2">
                                            <ErrorMessage name='state' />
                                        </p>
                                    </div>
                                    <div class="w-full md:w-[30%]">
                                        <label class="block py-2 px-2 rounded-sm tracking-wide">District</label>
                                        <Field
                                            type="text"
                                            name="district"
                                            placeholder="District"
                                            class="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        />

                                        <p class="text-red-600 text-sm mt-1 ml-2">
                                            <p class="text-red-600 text-sm mt-1 ml-2">
                                                <ErrorMessage name='district' />
                                            </p>
                                        </p>
                                    </div>
                                    <div class="w-full md:w-[30%]">
                                        <label class="block py-2 px-2 rounded-sm tracking-wide">Block</label>
                                        <Field
                                            type="text"
                                            name="block"
                                            placeholder="Block"
                                            class="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        />

                                        <p class="text-red-600 text-sm mt-1 ml-2">
                                            <p class="text-red-600 text-sm mt-1 ml-2">
                                                <ErrorMessage name='block' />
                                            </p>
                                        </p>
                                    </div>
                                </div>
                                <div class="w-full flex gap-4 justify-between">
                                    <div class="w-full md:w-[30%]">
                                        <label class="block py-2 px-2 rounded-sm tracking-wide">Panchayat </label>
                                        <Field
                                            type="text"
                                            name="panchayat"
                                            placeholder="Panchayat"
                                            class="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        />
                                        <p class="text-red-600 text-sm mt-1 ml-2">
                                            <p class="text-red-600 text-sm mt-1 ml-2">
                                                <ErrorMessage name='panchayat' />
                                            </p>
                                        </p>
                                    </div>
                                    <div class="w-full md:w-[30%]">
                                        <label class="block py-2 px-2 rounded-sm tracking-wide">Village</label>
                                        <Field
                                            type="text"
                                            name="village"
                                            placeholder="Village"
                                            class="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        />
                                        <p class="text-red-600 text-sm mt-1 ml-2">
                                            <p class="text-red-600 text-sm mt-1 ml-2">
                                                <ErrorMessage name='village' />
                                            </p>
                                        </p>
                                    </div>
                                    <div class="w-full md:w-[30%]">
                                        <label class="block py-2 px-2 rounded-sm tracking-wide">Aadhaar Number</label>
                                        <Field
                                            type="number"
                                            name="aadhaarnumber"
                                            placeholder="Aadhaar Number"
                                            class="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        />


                                        <p class="text-red-600 text-sm mt-1 ml-2">
                                            <p class="text-red-600 text-sm mt-1 ml-2">
                                                <ErrorMessage name='aadhaarnumber' />
                                            </p>
                                        </p>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="w-[20%] border-l border-gray-300 my-5">
                            <div class="w-full flex justify-evenly">

                                <button
                                    type="submit"
                                    class="focus:outline-none text-white bg-[#ff0000] hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-md text-sm px-5 py-2.5 me-2 mb-2 dark:bg-[#ff0000] dark:hover:bg-red-700 dark:focus:ring-red-900"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

export default CreateFranchise