import React, { useEffect, useState } from 'react'
import Http from '../../../Services/Http';

function Franchise_Query() {
    const [Student, setStudent] = useState([])
    useEffect(() => {
        const catogary = "franchise";
        const fetchData = async () => {
            try {
                let res = await Http({
                    url: `/query/getquery`,
                    method: "post",
                    data: { catogary }
                });
                setStudent(res?.data?.query)
            } catch (error) {
                console.log(error);
            }

        };
        fetchData()
    }, [])
    return (
        <div className='grid grid-cols-1 md:grid-cols-3'>
           
                {
                    Student?.map((item, index) => (
                        <div key={index}>
                            <div class="block m-5 max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

                                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{item?.name}</h5>
                                <p class="font-normal text-gray-700 dark:text-gray-400">{item?.message}</p>
                                <div className='flex gap-5'>
                                    <p class="font-normal text-gray-700 dark:text-gray-400">{item?.email}</p>
                                    <p class="font-normal text-gray-700 dark:text-gray-400">{item?.number}</p>
                                </div>

                            </div>
                        </div>
                    ))
                }
          
        </div>
    )
}

export default Franchise_Query