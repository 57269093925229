import React, { useContext, useEffect, useRef, useState } from 'react';
import MarkSheet from '../../../Assets/A_MarkSheet.png'
import { MyContext } from '../../../Context/MyContextProvider';
import { IoCloseSharp } from "react-icons/io5";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Http from '../../../Services/Http'


function M_Marksheet() {
    const { setMarksheet } = useContext(MyContext);
    const cartificateData = localStorage.getItem('cartificateData');
    const newCartificate = JSON.parse(cartificateData);
    const [pastCartificate, setpastCartificate] = useState({});

    const [getpastCartificate, setgetpastCartificate] = useState({});


    // console.log(pastCartificate);
    const [serialno, setSerialno] = useState('');
    const [course, setCourse] = useState('');
    const [courseperiod, setCourseperiod] = useState('');
    const [fullmarks, setFullmarks] = useState('');
    const [totalmarks, setTotalmarks] = useState('');
    const [pmarks, setPmarks] = useState('');
    const [grade, setGrade] = useState('');
    const [modules1, setModules1] = useState('');
    const [modules2, setModules2] = useState('');
    const [modules3, setModules3] = useState('');
    const [modules4, setModules4] = useState('');
    const [modules5, setModules5] = useState('');
    const [modules6, setModules6] = useState('');
    const [modules7, setModules7] = useState('');
    // const [category, setCategory] = useState('');

    // const [PDFbutton, setPDFbutton] = useState(false);
    const [SubmitButton, setSubmitButton] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await Http({
                    url: `/payment/getcertificate`,
                    method: "post",
                    data: { studentid: newCartificate?._id, category: "ADCA" }
                });
                setgetpastCartificate(res?.data?.certificate);

                if (res?.data?.message === "get_sussed_200") {
                    // setPDFbutton(true)


                } if (res?.data?.certificate === null) {
                    // setSubmitButton(false)

                }
            } catch (error) {
                console.log(`cartificate get error ${error}`);
            }
        }
        fetchData()
    }, [newCartificate?._id])

    useEffect(() => {
        if (pastCartificate) {
            const { course,serialno, courseperiod, fullmarks, totalmarks, pmarks, grade, modules1, modules2, modules3, modules4, modules5, modules6, modules7} = pastCartificate;
            setSerialno(serialno);
            setCourse(course);
            setCourseperiod(courseperiod);
            setFullmarks(fullmarks);
            setTotalmarks(totalmarks);
            setPmarks(pmarks);
            setGrade(grade);
            setModules1(modules1);
            setModules2(modules2);
            setModules3(modules3);
            setModules4(modules4);
            setModules5(modules5);
            setModules6(modules6);
            setModules7(modules7);
        }
    }, [pastCartificate])

    const handleClose = () => {
        setMarksheet(false)
    }
    const certificateRef = useRef(null);
    const handleDownloadPDF = () => {
        const input = certificateRef.current;

        html2canvas(input, { scale: 2 }) // Increase scale for higher resolution
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4'); // Set orientation to landscape
                pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // A4 size: 297mm × 210mm (switch width and height for p)
                pdf.save('Marksheet.pdf');
            });
    }
    ////////   create certificate  /////////

    const CertificateHandler = async () => {
        try {
            let serialno1 = `${getpastCartificate?.serialno}`
            let courseperiod1 = `${getpastCartificate?.startdate} To ${getpastCartificate?.enddate}`
           console.log(courseperiod);

            let res = await Http({
                url: `/payment/createmarksheet`,
                method: "post",
                data: { studentid: newCartificate?._id, serialno: serialno1.toUpperCase(),course, courseperiod:courseperiod1, fullmarks, totalmarks, pmarks, grade, modules1, modules2, modules3, modules4, modules5, modules6, modules7 }
            });
            if (res?.data?.message === "newMarkSheet_Created_201") {
               
                setMarksheet(false)
            }
            if (res?.data?.certificate === null) {
                setSubmitButton(false)
            }
            if (res?.data?.message === "serialno_already_exists") {
                alert("Serial No is unique")
            }
        } catch (error) {
            console.log(`cartificate error ${error}`);
        }

    }
    ////////   get certificate  /////////

    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await Http({
                    url: `/payment/getmarksheet`,
                    method: "post",
                    data: { studentid: newCartificate?._id }
                });
                setpastCartificate(res?.data?.markSheet);

                if (res?.data?.message === "Mark sheet found") {
                   
                    setSubmitButton(false)

                }if (res?.data?.message === "Mark sheet not found") {
                    setSubmitButton(true)
                }
                 if (res?.data?.MarkSheet === null) {
                    setSubmitButton(false)

                }
            } catch (error) {
                console.log(`cartificate get error ${error}`);
            }
        }
        fetchData()
    }, [newCartificate?._id,setMarksheet])

    ////////   update certificate   /////////

    const CertificateUpdateHandler = async () => {
        try {
            let res = await Http({
                url: `/payment/updatemarksheet`,
                method: "patch",
                data: { _id: pastCartificate?._id, serialno}
            });
            if (res?.data?.message === "updatedMarkSheet_Created_201") {
                setMarksheet(false)
            }
        } catch (error) {
            console.log(`cartificate error ${error}`);
        }
    }
    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white relative w-[90%] h-[95%] my-10 mx-auto  rounded-lg shadow-lg">
                <div onClick={handleClose} className='absolute top-2 right-2 cursor-pointer'>
                    <IoCloseSharp size={30} />
                </div>
                <div className='w-full h-full overflow-scroll'>
                    <div ref={certificateRef} className='w-[210mm] h-[297mm]' style={{ position: 'relative' }}>
                        <img src={MarkSheet} alt='A_Certificat' className='w-full h-full' style={{ position: 'absolute', top: 0, left: 0 }} />
                        <input type='text' value={getpastCartificate?.serialno} onChange={(e) => setSerialno(e?.target?.value)} className='w-[500px] absolute z-50 top-[27px] bg-transparent uppercase font-semibold text-ellipsis left-40 pl-2' />
                        <h1 className='absolute z-50 top-[240px] font-semibold text-ellipsis left-72 uppercase '>{newCartificate?.studentname}</h1>
                        <h1 className='absolute z-50 top-[273px] font-semibold text-ellipsis left-72 uppercase'>{newCartificate?.fathername} </h1>
                        <input type='text' value={getpastCartificate?.course} onChange={(e) => setCourse(e?.target?.value)} className='w-[500px] uppercase absolute z-50 top-[302px] bg-transparent font-semibold text-ellipsis left-64 pl-8' />
                        <input type='text' value={`${getpastCartificate?.startdate} To ${getpastCartificate?.enddate}`} onChange={(e) => setCourseperiod(e?.target?.value)} className='w-[500px] uppercase absolute z-50 top-[336px] bg-transparent font-semibold text-ellipsis left-64 pl-8' />
                        <h1 className='absolute z-50 top-[370px] font-semibold text-ellipsis left-72 uppercase'>{getpastCartificate?.franchiseid?.franchisename}</h1>

                        {/*  */}
                        <input type='number' value={modules1} onChange={(e) => setModules1(e?.target?.value)} className='w-28 absolute z-50 top-[509px] bg-transparent font-semibold text-ellipsis left-[460px] pl-12' />
                        <input type='number' value={modules2} onChange={(e) => setModules2(e?.target?.value)} className='w-28 absolute z-50 top-[595px] bg-transparent font-semibold text-ellipsis left-[460px] pl-12' />
                        <input type='number' value={modules3} onChange={(e) => setModules3(e?.target?.value)} className='w-28 absolute z-50 top-[660px] bg-transparent font-semibold text-ellipsis left-[460px] pl-12' />
                        <input type='number' value={modules4} onChange={(e) => setModules4(e?.target?.value)} className='w-28 absolute z-50 top-[720px] bg-transparent font-semibold text-ellipsis left-[460px] pl-12' />
                        <input type='number' value={modules5} onChange={(e) => setModules5(e?.target?.value)} className='w-28 absolute z-50 top-[760px] bg-transparent font-semibold text-ellipsis left-[460px] pl-12' />
                        <input type='number' value={modules6} onChange={(e) => setModules6(e?.target?.value)} className='w-28 absolute z-50 top-[795px] bg-transparent font-semibold text-ellipsis left-[460px] pl-12' />
                        <input type='number' value={modules7} onChange={(e) => setModules7(e?.target?.value)} className='w-28 absolute z-50 top-[820px] bg-transparent font-semibold text-ellipsis left-[460px] pl-12' />


                        {/*  */}
                        <input type='text' value={pmarks} onChange={(e) => setPmarks(e?.target?.value)} className='w-28 absolute z-50 top-[630px] bg-transparent font-semibold text-ellipsis left-[540px] pl-12' />
                        <input type='text' value={grade} onChange={(e) => setGrade(e?.target?.value)} className='w-28 absolute z-50 top-[630px] bg-transparent font-semibold text-ellipsis left-[650px] pl-2' />
                        <input type='text' value={fullmarks} onChange={(e) => setFullmarks(e?.target?.value)} className='w-20 absolute z-50 top-[850px] bg-transparent font-semibold text-ellipsis left-[390px] pl-8  ' />
                        <input type='text' value={totalmarks} onChange={(e) => setTotalmarks(e?.target?.value)} className='w-20 absolute z-50 top-[850px] bg-transparent font-semibold text-ellipsis left-[475px] pl-8' />
                    </div>
                </div>
                <div className='absolute top-10 right-24'>
                   
                        <button onClick={handleDownloadPDF} class="relative  inline-flex items-center justify-center px-10 py-4 overflow-hidden font-mono font-medium tracking-tighter text-white bg-gray-800 rounded-lg group">
                            <span class="absolute w-0 h-0 transition-all duration-500 ease-out bg-green-500 rounded-full group-hover:w-56 group-hover:h-56"></span>
                            <span class="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent to-gray-700"></span>
                            <span class="relative" >Download PDF</span>
                        </button>
                    
                    {

                         SubmitButton &&
                        <div className='block mx-auto mt-5'>
                            <button onClick={CertificateHandler} class="relative   inline-flex items-center justify-center px-10 py-4 overflow-hidden font-mono font-medium tracking-tighter text-white bg-gray-800 rounded-lg group">
                                <span class="absolute w-0 h-0 transition-all duration-500 ease-out bg-green-500 rounded-full group-hover:w-56 group-hover:h-56"></span>
                                <span class="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent to-gray-700"></span>
                                <span class="relative" >Submit</span>
                            </button>
                        </div>
                    }

                    {
                        SubmitButton === false&& 
                        <div className='block mx-auto mt-5'>
                            <button onClick={CertificateUpdateHandler} class="relative   inline-flex items-center justify-center px-10 py-4 overflow-hidden font-mono font-medium tracking-tighter text-white bg-gray-800 rounded-lg group">
                                <span class="absolute w-0 h-0 transition-all duration-500 ease-out bg-green-500 rounded-full group-hover:w-56 group-hover:h-56"></span>
                                <span class="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent to-gray-700"></span>
                                <span class="relative" >Update</span>
                            </button>
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}

export default M_Marksheet