import React, { useState, useEffect, useContext } from 'react';
import Http from '../../../Services/Http';
import { MdCurrencyRupee } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { MyContext } from '../../../Context/MyContextProvider';
import UpdateCourse from './UpdateCourse';

function TotalCourse() {
  const { setCourseEdit,CourseEdit } = useContext(MyContext)
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const user = localStorage.getItem('userdata');
  const loginUser = JSON.parse(user);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      let res = await Http({
        url: `/course/getcourse`,
        method: "get",
        data: {}
      });
      setCourses(res?.data?.course);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // Handle error
    }
  };
  //  deleted
  const deleteCouseHnadler = async(_id)=>{
    try {
      let res = await Http({
        url: `/course/getdelete`,
        method: "delete",
        data: {_id}
      });
      // console.log(_id);
      if (res.data === "Course_deleted_201") {
        fetchData()
      }
      setCourses(res?.data?.course);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // Handle error
    }
  }
  const courseHandler = (item)=>{
    localStorage.setItem('courseItemData',JSON.stringify(item))
    if (loginUser?.role === "director" ) {
      setCourseEdit(true)
    }
    
  }
  return (
    <div>
      {loading ? (
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-[95%] mx-auto'>
          <div className="max-w-sm rounded-md border overflow-hidden shadow-lg bg-white text-white p-4 animate-pulse">
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-4 py-1">
                <div className="space-y-2">
                  <div className="h-8 bg-gray-400 rounded"></div>
                  <div className="h-12 bg-gray-400 rounded w-5/6"></div>

                </div>
                <div>
                  <div className="h-4 mt-1 bg-gray-400 rounded"></div>
                  <div className="h-4 mt-1 bg-gray-400 rounded w-5/6"></div>
                  <div className="h-4 mt-1 bg-gray-400 rounded"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-sm rounded-md border overflow-hidden shadow-lg bg-white text-white p-4 animate-pulse">
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-4 py-1">
                <div className="space-y-2">
                  <div className="h-8 bg-gray-400 rounded"></div>
                  <div className="h-12 bg-gray-400 rounded w-5/6"></div>

                </div>
                <div>
                  <div className="h-4 mt-1 bg-gray-400 rounded"></div>
                  <div className="h-4 mt-1 bg-gray-400 rounded w-5/6"></div>
                  <div className="h-4 mt-1 bg-gray-400 rounded"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-sm rounded-md border overflow-hidden shadow-lg bg-white text-white p-4 animate-pulse">
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-4 py-1">
                <div className="space-y-2">
                  <div className="h-8 bg-gray-400 rounded"></div>
                  <div className="h-12 bg-gray-400 rounded w-5/6"></div>

                </div>
                <div>
                  <div className="h-4 mt-1 bg-gray-400 rounded"></div>
                  <div className="h-4 mt-1 bg-gray-400 rounded w-5/6"></div>
                  <div className="h-4 mt-1 bg-gray-400 rounded"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-sm rounded-md border overflow-hidden shadow-lg bg-white text-white p-4 animate-pulse">
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-4 py-1">
                <div className="space-y-2">
                  <div className="h-8 bg-gray-400 rounded"></div>
                  <div className="h-12 bg-gray-400 rounded w-5/6"></div>

                </div>
                <div>
                  <div className="h-4 mt-1 bg-gray-400 rounded"></div>
                  <div className="h-4 mt-1 bg-gray-400 rounded w-5/6"></div>
                  <div className="h-4 mt-1 bg-gray-400 rounded"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-sm rounded-md border overflow-hidden shadow-lg bg-white text-white p-4 animate-pulse">
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-4 py-1">
                <div className="space-y-2">
                  <div className="h-8 bg-gray-400 rounded"></div>
                  <div className="h-12 bg-gray-400 rounded w-5/6"></div>

                </div>
                <div>
                  <div className="h-4 mt-1 bg-gray-400 rounded"></div>
                  <div className="h-4 mt-1 bg-gray-400 rounded w-5/6"></div>
                  <div className="h-4 mt-1 bg-gray-400 rounded"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-sm rounded-md border overflow-hidden shadow-lg bg-white text-white p-4 animate-pulse">
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-4 py-1">
                <div className="space-y-2">
                  <div className="h-8 bg-gray-400 rounded"></div>
                  <div className="h-12 bg-gray-400 rounded w-5/6"></div>

                </div>
                <div>
                  <div className="h-4 mt-1 bg-gray-400 rounded"></div>
                  <div className="h-4 mt-1 bg-gray-400 rounded w-5/6"></div>
                  <div className="h-4 mt-1 bg-gray-400 rounded"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-sm rounded-md border overflow-hidden shadow-lg bg-white text-white p-4 animate-pulse">
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-4 py-1">
                <div className="space-y-2">
                  <div className="h-8 bg-gray-400 rounded"></div>
                  <div className="h-12 bg-gray-400 rounded w-5/6"></div>

                </div>
                <div>
                  <div className="h-4 mt-1 bg-gray-400 rounded"></div>
                  <div className="h-4 mt-1 bg-gray-400 rounded w-5/6"></div>
                  <div className="h-4 mt-1 bg-gray-400 rounded"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-sm rounded-md border overflow-hidden shadow-lg bg-white text-white p-4 animate-pulse">
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-4 py-1">
                <div className="space-y-2">
                  <div className="h-8 bg-gray-400 rounded"></div>
                  <div className="h-12 bg-gray-400 rounded w-5/6"></div>

                </div>
                <div>
                  <div className="h-4 mt-1 bg-gray-400 rounded"></div>
                  <div className="h-4 mt-1 bg-gray-400 rounded w-5/6"></div>
                  <div className="h-4 mt-1 bg-gray-400 rounded"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-sm rounded-md border overflow-hidden shadow-lg bg-white text-white p-4 animate-pulse">
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-4 py-1">
                <div className="space-y-2">
                  <div className="h-8 bg-gray-400 rounded"></div>
                  <div className="h-12 bg-gray-400 rounded w-5/6"></div>

                </div>
                <div>
                  <div className="h-4 mt-1 bg-gray-400 rounded"></div>
                  <div className="h-4 mt-1 bg-gray-400 rounded w-5/6"></div>
                  <div className="h-4 mt-1 bg-gray-400 rounded"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-sm rounded-md border overflow-hidden shadow-lg bg-white text-white p-4 animate-pulse">
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-4 py-1">
                <div className="space-y-2">
                  <div className="h-8 bg-gray-400 rounded"></div>
                  <div className="h-12 bg-gray-400 rounded w-5/6"></div>

                </div>
                <div>
                  <div className="h-4 mt-1 bg-gray-400 rounded"></div>
                  <div className="h-4 mt-1 bg-gray-400 rounded w-5/6"></div>
                  <div className="h-4 mt-1 bg-gray-400 rounded"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-sm rounded-md border overflow-hidden shadow-lg bg-white text-white p-4 animate-pulse">
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-4 py-1">
                <div className="space-y-2">
                  <div className="h-8 bg-gray-400 rounded"></div>
                  <div className="h-12 bg-gray-400 rounded w-5/6"></div>

                </div>
                <div>
                  <div className="h-4 mt-1 bg-gray-400 rounded"></div>
                  <div className="h-4 mt-1 bg-gray-400 rounded w-5/6"></div>
                  <div className="h-4 mt-1 bg-gray-400 rounded"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-sm rounded-md border overflow-hidden shadow-lg bg-white text-white p-4 animate-pulse">
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-4 py-1">
                <div className="space-y-2">
                  <div className="h-8 bg-gray-400 rounded"></div>
                  <div className="h-12 bg-gray-400 rounded w-5/6"></div>

                </div>
                <div>
                  <div className="h-4 mt-1 bg-gray-400 rounded"></div>
                  <div className="h-4 mt-1 bg-gray-400 rounded w-5/6"></div>
                  <div className="h-4 mt-1 bg-gray-400 rounded"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-[95%] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
          {courses?.map((course, index) => (
            <div key={index} onClick={()=>courseHandler(course)} className="max-w-sm cursor-pointer relative rounded-md  border overflow-hidden shadow-lg bg-white">
              <div className="px-6 h-52 py-4">
                {
                  !course?.image ?
                    <div className='w-full h-48 bg-gray-200 rounded-md'>

                    </div> :
                    <img className="w-full h-full rounded-md" src={course?.image} alt="Course" />
                }
                {
                  loginUser?.role === "director"
                  && <div onClick={()=>deleteCouseHnadler(course?._id)} className='p-2 rounded-full border cursor-pointer absolute top-2 right-2 bg-gray-200'>
                  <MdDelete size={25} className=' ' />
                </div>
                }
                

              </div>
              <div className="px-6 pb-4 ">
                <p className="text-gray-900 text-base font-semibold">Duration: <span className="text-blue-600">{course?.duration}</span></p>
                <p className="text-green-600 text-base flex items-center  font-semibold">
                  Fees: <MdCurrencyRupee size={20} className='mr-1' /> {course?.fees}
                </p>
                <p className="text-gray-900 text-base font-semibold">Eligibility: <span className="text-blue-600">{course?.eligibility}</span></p>

              </div>
            </div>
          ))}
        </div>
      )}
        {
        CourseEdit &&
        <UpdateCourse />
      }
    </div>
  );
}

export default TotalCourse;
