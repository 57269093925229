import React from 'react'

function ScrollAbout() {
  return (
    <div className='bg-[#ebebeb] w-full h-[350px] py-24'>
        <h1 className='text-[#16275a] text-2xl md:text-3xl lg:text-4xl text-center font-medium'>Facts About Computers</h1>
        <h1 className='text-center text-yellow-500 text-2xl font-semibold mb-5'>|</h1>
        <div>
            <h1 className='text-center text-blue-800'>Management has been proven to be the most effective method of delivering products within cost</h1>
            <p className='text-center text-xl font-medium text-yellow-500'>Adarsh Kumar</p>
        </div>
    </div>
  )
}

export default ScrollAbout