import React from 'react';
import { IoIosPeople } from "react-icons/io";
import { SlEmotsmile } from "react-icons/sl";
import { CiTrophy } from "react-icons/ci";
import { TbBuildingEstate } from "react-icons/tb";

function BranchDetails() {
  return (
    <div className='bg-[#0A2A8A] w-full md:h-[350px] flex items-center justify-center'>
      <div className='w-[90%] md:w-[70%] mx-auto md:flex justify-between text-white'>
        <div className='text-center mb-8 md:mb-0'>
          <IoIosPeople size={60} className='mb-3 mx-auto md:ml-5'/>
          <h1 className='font-bold text-4xl mb-3'>35699+</h1>
          <p className='md:mr-5'>Students</p>
        </div>
        <div className='text-center mb-8 md:mb-0'>
          <SlEmotsmile size={60} className='mb-3 mx-auto md:mx-0'/>
          <h1 className='font-bold text-4xl mb-3'>259+</h1>
          <p>Branches</p>
        </div>
        <div className='text-center mb-8 md:mb-0'>
          <CiTrophy size={60} className='mb-3 mx-auto md:mx-0'/>
          <h1 className='font-bold text-4xl mb-3'>50</h1>
          <p>Districts</p>
        </div>
        <div className='text-center mb-8 md:mb-0'>
          <TbBuildingEstate size={60} className='mb-3 mx-auto md:mx-0'/>
          <h1 className='font-bold text-4xl mb-3'>8+</h1>
          <p>States</p>
        </div>
      </div>
    </div>
  )
}

export default BranchDetails;
