import React from 'react'
import Navbar from '../Components/Navbar'
import ImageSlider from '../Pages/ImageSlider/ImageSlider'
// import ButtomHeadling from '../Pages/ButtomHeadling/ButtomHeadling'
import Footer from '../Components/Footer'
import AboutH1 from '../Pages/HomeAbout/AboutH1'
import Mission from '../Pages/HomeAbout/Mission'
import BranchDetails from '../Pages/HomeAbout/BranchDetails'
import TrainingDetails from '../Pages/HomeAbout/TrainingDetails'
import ScrollAbout from '../Pages/HomeAbout/ScrollAbout'
import Location from '../../FrontWebsite/Pages/Loacation/Loacation'
import MobileNavbar from '../Components/MobileNavbar'

function Home() {
  return (
    <>
      <div className='w-full'>
        <div className='hidden md:block'> 
        <Navbar/>
        </div>
       <div className='md:hidden'>
        <MobileNavbar />
       </div>
        <ImageSlider />
        <AboutH1 />
        <Mission />
        <BranchDetails />
        <TrainingDetails />
        <ScrollAbout />
        {/* <ButtomHeadling/> */}
        <Location />
        <Footer />
      </div>
    </>
  )
}

export default Home