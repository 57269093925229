import React, { useContext, useEffect, useState } from 'react'
import Http from '../../../Services/Http';
import { DateTime } from 'date-time-helper-x';
import { MyContext } from '../../../Context/MyContextProvider';
import TCreatecertificate from './T_Createcertificate';
import ACreatecertificate from './A_Createcertificate';
import MMarksheet from './M_Marksheet';
import PaymentMethod from './PaymentMethod';

function Totalcertificate() {
  const { CertificateCreate, setCertificateCreate,paymentPage, setpaymentPage, T_CertificateCreate, setT_CertificateCreate, Marksheet, setMarksheet } = useContext(MyContext);
  const [students, setStudents] = useState([]);
  const [baseStudents, setbaseStudents] = useState([])
  const user = localStorage.getItem('userdata');
  const loginUser = JSON.parse(user);
  let newUserlogin = loginUser?.franchiserid?._id
  let studentloginid = loginUser?.studentid?._id
  const [loading, setloading] = useState(true)
  const [paymentDetail, setpaymentDetail] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await Http({
          url: `/student/getstudent`,
          method: "get",
          data: {}
        });
        const students = res?.data?.students;
        setStudents(students);
        setloading(false);
      } catch (error) {
        console.log(`Server Catch Error ${error}`);
        setloading(false);
      }
    };

    fetchData(); // Call fetchData here

  }, []); // Empty dependency array



  useEffect(() => {
    if (loginUser.role === "director") {
      setbaseStudents(students);

    } else if (loginUser?.role === "franchise") {
      const student = students.filter((item) => {
        return item?.franchise_or_director_id?._id === newUserlogin
      })
      setbaseStudents(student)

    } else if (loginUser?.role === "student") {
      const student = students.filter((item) => {
        return item?._id === studentloginid && item?.franchise_or_director_id?._id === newUserlogin
      })
      setbaseStudents(student)

    }
  }, [newUserlogin, loginUser?.role, students, studentloginid])

  const A_CertificateHandler = (item) => {

    localStorage.setItem('cartificateData', JSON.stringify(item));
    setCertificateCreate(true)
  }
  const T_CertificateHandler = (item) => {
    setT_CertificateCreate(true)
    localStorage.setItem('cartificateData', JSON.stringify(item));
  }
  const MarkSheetHandler = (item) => {
    setMarksheet(true)
    localStorage.setItem('cartificateData', JSON.stringify(item));
  }
  const PaymentPage = (item)=>{
    const paymentStudent = JSON.stringify(item)
    localStorage.setItem("paymentStudent",paymentStudent)
    setTimeout(() => {
      setpaymentPage(true)
    }, 100);
    
  }
  // /////// franchise time start //////////
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await Http({
          url: `/payment/getpayment`,
          method: "get",
          data: {},
        });
        setpaymentDetail(res?.data?.paymentDetail)

      } catch (error) {

      }
    }
    fetchData()
  }, [])

  ///////// franchise time end //////////

  //////// directortime ///////////
  const RequestAssept = async (_id) => {
    try {
       let res = await Http({
        url: `/payment/updatepayment`,
        method: "patch",
        data: { payment: true, studentid: _id }
      })
      if (res?.data?.message === "Payment updated successfully") {
        window.location.reload()
    }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div>
      {
        loading ?
          (
            <>
              <div className="w-[95%] mt-8 mx-auto">
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-700 bg-white shadow-md rounded-lg overflow-hidden">
                    <thead className="bg-gray-700">
                      <tr>
                        <th className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">

                        </th>
                        <th className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">

                        </th>
                        <th className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">

                        </th>
                        <th className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">

                        </th>

                      </tr>
                    </thead>
                    <tbody className="divide-y divide-white ">
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) :
          (
            <>
              <div className='w-[95%] mt-8 mx-auto relative'>
                <div className="w-full shadow-md sm:rounded-lg overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-700">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Student Name
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Franchise Name
                        </th>
                        {
                          loginUser.role === "director" &&
                          <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                           Payment Request
                          </th>
                        }
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Father Name
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Mother Name
                        </th>

                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Address
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Qualification
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Date of Birth
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Aadhaar Number
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Gender
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Status
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Mobile
                        </th>
                        {
                          loginUser.role === "director" &&
                          <>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                              ADCA Certificate
                            </th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                              Typing Certificate
                            </th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                              Marksheet
                            </th>
                          </>
                        }
                        {
                          loginUser.role === "franchise" &&
                          <>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                              Certificate
                            </th>
                          </>
                        }

                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 text-center">
                      {baseStudents?.map((item, index) => (
                        <tr key={index}>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.studentname}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.franchise_or_director_id?.franchisename}</td>
                          {
                            loginUser?.role === "director" &&
                            <td onClick={()=>RequestAssept(item?._id)} className="px-6 py-4 whitespace-nowrap bg-blue-600 rounded-md shadow-md cursor-pointer text-white ">

                              <button className=" hover:underline" >
                                {paymentDetail.some(Pay => Pay?.studentid === item?._id) ? (
                                  paymentDetail.find(Pay => Pay?.studentid === item?._id).payment  ? "Payment Complete" : "Update Payment"
                                ) : "No Request "}

                              </button>
                            </td>
                          }
                          <td className="px-6 py-4 whitespace-nowrap">{item?.fathername}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.mothername}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.address}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.qualification}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{DateTime(item?.dateofbirth, "DDMMYYYY",)}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.aadharno}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.gender}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.status ? "Study Running" : "Study Complate"}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.mobile}</td>
                          {
                            loginUser.role === "director" &&
                            <>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <button onClick={() => A_CertificateHandler(item)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">ADCA Certificate</button>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <button onClick={() => T_CertificateHandler(item)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Typing Certificate</button>
                              </td>
                              <td onClick={() => MarkSheetHandler(item)} className="px-6 py-4 whitespace-nowrap">
                                <button className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Marksheet</button>
                              </td>
                            </>
                          }
                          {
                            loginUser?.role === "franchise" &&
                            <td className="px-6 py-4 whitespace-nowrap">
                              <button className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={()=>PaymentPage(item)}>
                                {paymentDetail?.some(Pay => Pay?.studentid === item?._id) ? (
                                  paymentDetail?.find(Pay => Pay?.studentid === item?._id).payment ? "Complete" : "Pending"
                                ) : "Request Certificate"}

                              </button>
                            </td>

                          }



                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

              </div>
            </>
          )
      }
      {
        CertificateCreate && <ACreatecertificate />
      }
      {
        T_CertificateCreate && <TCreatecertificate />
      }
      {
        Marksheet && <MMarksheet />
      }
      {
        paymentPage && <PaymentMethod/>
      }
    </div>
  )
}

export default Totalcertificate
