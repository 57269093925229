import React, { useContext, useEffect, useRef, useState } from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { MyContext } from '../../../Context/MyContextProvider';
import TCertificat from '../../../Assets/A_Certificat_Typing.png';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Http from '../../../Services/Http';

function T_Createcertificate() {
    const { setT_CertificateCreate } = useContext(MyContext);
    const cartificateData = localStorage.getItem('cartificateData');
    const newCartificate = JSON.parse(cartificateData);
    const [serialno, setserialno] = useState('');
    const [startdate, setstartdate] = useState('');
    const [enddate, setenddate] = useState('');
    const [issuedate, setissuedate] = useState('');
    const [pastCartificate, setpastCartificate] = useState({});
    const [PDFbutton, setPDFbutton] = useState(false);
    const [SubmitButton, setSubmitButton] = useState(true);
    console.log("pastCartificate",pastCartificate);
    const [typing_english, Settyping_english] = useState('');
    const [typing_hindi, Settyping_hindi] = useState('');
    const [typing_urdu, Settyping_urdu] = useState('');

    const [stg_english, Setstg_english] = useState('');
    const [stg_hindi, Setstg_hindi] = useState('');
    const [stg_urdu, setstg_urdu] = useState('');

    useEffect(() => {
        if (pastCartificate) {
            const { enddate, issuedate, serialno, startdate ,typing_english,
                typing_hindi,typing_urdu,stg_english,stg_hindi,stg_urdu
            } = pastCartificate;
            setserialno(serialno)
            setstartdate(startdate)
            setenddate(enddate)
            setissuedate(issuedate)

            Settyping_english(typing_english)
            Settyping_hindi(typing_hindi)
            Settyping_urdu(typing_urdu)

            Setstg_english(stg_english)
            Setstg_hindi(stg_hindi)
            setstg_urdu(stg_urdu)
        }
    }, [pastCartificate])

    const certificateRef = useRef(null);

    const handleClose = () => {
        setT_CertificateCreate(false);
    }

    const handleDownloadPDF = () => {
        const input = certificateRef.current;

        html2canvas(input, { scale: 2 }) // Increase scale for higher resolution
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('landscape', 'mm', 'a4'); // Set orientation to landscape
                pdf.addImage(imgData, 'PNG', 0, 0, 297, 210); // A4 size: 297mm × 210mm (switch width and height for landscape)
                pdf.save('Typing-certificate.pdf');
            });
    }
    ////////   create certificate  /////////

    const CertificateHandler = async () => {
        let serialno1 = `${serialnoHandler(newCartificate?.toenddate)}/T/${String(newCartificate?.aadharno).slice(-4)}`
        let startdate1 = startdate;
        let enddate1 = enddate;
        try {
            let res = await Http({
                url: `/payment/createcertificate`,
                method: "post",
                data: { studentid: newCartificate?._id, category: "TYPING", serialno:serialno1.toUpperCase(), startdate:startdate1, enddate:enddate1, issuedate ,

                    typing_english,
                    typing_hindi,
                    typing_urdu,
    
                    stg_english,
                    stg_hindi,
                    stg_urdu
                }
            });
            if (res?.data?.message === "newCertificate_Created_201") {
                setPDFbutton(true)
                setT_CertificateCreate(false)
            }
            if (res?.data?.message === "serialno_already_exists") {
                alert("Serial No is unique")
            }
        } catch (error) {
            console.log(`cartificate error ${error}`);
        }

    }
    ////////   get certificate  /////////

    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await Http({
                    url: `/payment/getcertificate`,
                    method: "post",
                    data: { studentid: newCartificate?._id, category: "TYPING" }
                });
                setpastCartificate(res?.data?.certificate);
                if (res.data.message === "get_sussed_200") {
                    setPDFbutton(true) 
                    
                }if (res?.data?.certificate === null) {
                    setSubmitButton(false)
                }
                
            } catch (error) {
                console.log(`cartificate get error ${error}`);
            }
        }
        fetchData()
    }, [newCartificate?._id])

    ////////   update certificate   /////////

    const CertificateUpdateHandler = async () => {
        try {
            let res = await Http({
                url: `/payment/updatecertificate`,
                method: "patch",
                data: { _id: pastCartificate?._id, serialno, startdate, enddate, issuedate ,
                    typing_english,
                    typing_hindi,
                    typing_urdu,
    
                    stg_english,
                    stg_hindi,
                    stg_urdu
                }
            });
            if (res.data.message === "updatedcertificate_Created_201") {
                setT_CertificateCreate(false)
            }
            
            
        } catch (error) {
            console.log(`cartificate error ${error}`);
        }
    }
    const serialnoHandler = (item)=>{
        if (item) {
            const dateObject = new Date(item); // Parse the date string into a Date object
            return dateObject.getFullYear(); // Return the year extracted from the Date object
        } else {
            // Handle cases where item is null or undefined
            return null; // or throw an error, depending on your requirements
        }
    }
    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white relative w-[90%] h-[95%] my-10 mx-auto  rounded-lg shadow-lg">
                <div onClick={handleClose} className='absolute top-2 right-2 cursor-pointer'>
                    <IoCloseSharp size={30} />
                </div>
                <div className='w-full h-full overflow-scroll'>
                    <div ref={certificateRef} className='w-[297mm] h-[210mm]' style={{ position: 'relative' }}>
                        <img src={TCertificat} alt='A_Certificat' className='w-full h-full' style={{ position: 'absolute', top: 0, left: 0 }} />

                        <input type='text' value={`${serialnoHandler(newCartificate?.toenddate)}/T/${String(newCartificate?.aadharno).slice(-4)}`} onChange={(e) => setserialno(e?.target?.value)} className='absolute uppercase z-50 top-[95px] bg-transparent text-xl font-semibold text-ellipsis left-64 ' />
                        <h1 className='absolute z-50 top-[347px] font-semibold text-ellipsis left-96 uppercase'>{newCartificate?.studentname}</h1>
                        <h1 className='absolute z-50 top-[390px] font-semibold text-ellipsis left-96 uppercase'>{newCartificate?.fathername} </h1>
                        <h1 className='absolute z-50 top-[430px] font-semibold text-ellipsis left-96 uppercase'>{newCartificate?.franchise_or_director_id?.franchisename}</h1>
                        <input type='text' value={startdate} onChange={(e) => setstartdate(e.target.value)} className='w-48 uppercase absolute z-50 bg-transparent top-[470px] font-semibold text-ellipsis left-44 ' />
                        <input type='text' value={enddate} onChange={(e) => setenddate(e.target.value)} className='w-48 uppercase absolute z-50 bg-transparent top-[470px] font-semibold text-ellipsis left-[380px] ' />
                        <h1 className='absolute z-50 top-[470px] font-semibold text-ellipsis left-[800px] '>HINDI & ENGLISH</h1>
                        <input type='text' value={issuedate} onChange={(e) => setissuedate(e.target.value)} className='w-48 uppercase absolute z-50 bg-transparent top-[550px] font-semibold text-ellipsis left-96 ' />
                        
                        <input type='text' value={typing_english} onChange={(e) => Settyping_english(e.target.value)} className='w-11 uppercase absolute z-50 bg-transparent top-[622px] font-semibold text-ellipsis left-48 ' />
                        <input type='text' value={typing_hindi} onChange={(e) => Settyping_hindi(e.target.value)} className='w-11 uppercase absolute z-50 bg-transparent top-[642px] font-semibold text-ellipsis left-48' />
                        <input type='text' value={typing_urdu} onChange={(e) => Settyping_urdu(e.target.value)} className='w-11 uppercase absolute z-50 bg-transparent top-[662px] font-semibold text-ellipsis left-48 ' />

                        <input type='text' value={stg_english} onChange={(e) => Setstg_english(e.target.value)} className='w-11 uppercase absolute z-50 bg-transparent top-[622px] font-semibold text-ellipsis left-[400px] ' />
                        <input type='text' value={stg_hindi} onChange={(e) => Setstg_hindi(e.target.value)} className='w-11 uppercase absolute z-50 bg-transparent top-[642px] font-semibold text-ellipsis left-[400px] ' />
                        <input type='text' value={stg_urdu} onChange={(e) => setstg_urdu(e.target.value)} className='w-11 uppercase absolute z-50 bg-transparent top-[662px] font-semibold text-ellipsis left-[400px] ' />

                    </div>
                </div>
                <div className='absolute top-10 right-24'>
                    {
                        PDFbutton &&
                        <button onClick={handleDownloadPDF} class="relative  inline-flex items-center justify-center px-10 py-4 overflow-hidden font-mono font-medium tracking-tighter text-white bg-gray-800 rounded-lg group">
                            <span class="absolute w-0 h-0 transition-all duration-500 ease-out bg-green-500 rounded-full group-hover:w-56 group-hover:h-56"></span>
                            <span class="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent to-gray-700"></span>
                            <span class="relative" >Download PDF</span>
                        </button>
                    }
                    {
                        SubmitButton === false &&
                        <div className='block mx-auto mt-5'>
                            <button onClick={CertificateHandler} class="relative   inline-flex items-center justify-center px-10 py-4 overflow-hidden font-mono font-medium tracking-tighter text-white bg-gray-800 rounded-lg group">
                                <span class="absolute w-0 h-0 transition-all duration-500 ease-out bg-green-500 rounded-full group-hover:w-56 group-hover:h-56"></span>
                                <span class="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent to-gray-700"></span>
                                <span class="relative" >Submit</span>
                            </button>
                        </div>
                    }

                    {
                        SubmitButton &&
                        <div className='block mx-auto mt-5'>
                            <button onClick={CertificateUpdateHandler} class="relative   inline-flex items-center justify-center px-10 py-4 overflow-hidden font-mono font-medium tracking-tighter text-white bg-gray-800 rounded-lg group">
                                <span class="absolute w-0 h-0 transition-all duration-500 ease-out bg-green-500 rounded-full group-hover:w-56 group-hover:h-56"></span>
                                <span class="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent to-gray-700"></span>
                                <span class="relative" >Update</span>
                            </button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default T_Createcertificate;
