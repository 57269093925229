import React, { createContext, useState } from "react";
const MyContext = createContext();

const MyContextProvider = ({ children }) => {  
  const [FranchisNavbar, setFranchisNavbar] = useState(false);
  const [Course, setCourse] = useState(false);
  const [StudentNavbar, setStudentNavbar] = useState(false);
  const [FalseRegister, setFalseRegister] = useState(false);
  const [CertificateCreate, setCertificateCreate] = useState(false);
  const [T_CertificateCreate, setT_CertificateCreate] = useState(false);
  const [Marksheet, setMarksheet] = useState(false)
  const [StudentToggle, setStudentToggle] = useState(false)
  const [paymentPage, setpaymentPage] = useState(false)
  const [editFranchise, seteditFranchise] = useState(false);
  const [CourseEdit, setCourseEdit] = useState(false)
  // useEffect(() => {
  //   localStorage.setItem("myPostupdateData", JSON.stringify(update));
  //   localStorage.setItem("myJobupdateData", JSON.stringify(Postupdate));
  // }, [update,Postupdate]);

  // function getupdateData() {
  //   const PostData = localStorage.getItem("myupdateData");
    
  //   // console.log(updateData);
  //   return PostData ? JSON.parse(PostData) : null;
    
  // }
  // function getjobdata(){
  //   const Jobdata = localStorage.getItem("myJobupdateData");
  //   return Jobdata ? JSON.parse(Jobdata) : null;
  // }
  // console.log(getupdateData());
  return (
    <MyContext.Provider
      value={{
        CourseEdit, setCourseEdit,editFranchise, seteditFranchise, FranchisNavbar,T_CertificateCreate,Marksheet,StudentToggle,paymentPage, setpaymentPage, setStudentToggle, setMarksheet, setT_CertificateCreate, setFranchisNavbar ,Course, setCourse ,StudentNavbar, setStudentNavbar ,FalseRegister, setFalseRegister ,CertificateCreate, setCertificateCreate
      }}>
      {children}
    </MyContext.Provider>
  );
};

export { MyContext, MyContextProvider };