import React, { useEffect, useState } from 'react'
import Http from '../../Services/Http'
// import { useNavigate } from 'react-router-dom';


function TotalRegister() {
    // const navigate = useNavigate()
    const user = localStorage.getItem('userdata');
    const loginUser = JSON.parse(user);
    const userRole = loginUser?.role;
    const [loading, setloading] = useState(true)
    const [userByeAuth, setuserByeAuth] = useState([]);
    const franchiseid = userRole?.franchiserid?._id
    // console.log(userByeAuth);
    useEffect(() => {
        const fechData = async () => {
            try {
                let res = await Http({
                    url: `/auth/getlogindata`,
                    method: "post",
                    data: { role: userRole, _id:franchiseid}
                });
                const loginData = res?.data?.userData;
                setuserByeAuth(loginData);
                setloading(false)
            } catch (error) {
                console.log(`server From Catch`);
                setloading(false)
            }
        }
        fechData()
    }, [userRole,franchiseid])

    const deleteLogin = async (_id)=>{

        try {
            let res = Http({
                url:`/auth/deleteregister/${_id}`,
                method:"delete",
                data:{}
            })
            if (res.data?.message === "User deleted successfully") {
                alert("User deleted successfully")
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div>
            {
                loading ?
                    (
                        <>
                            <div className="w-[95%] mt-8 mx-auto">
                                <div className="overflow-x-auto">
                                    <table className="min-w-full divide-y divide-gray-700 bg-white shadow-md rounded-lg overflow-hidden">
                                        <thead className="bg-gray-700">
                                            <tr>
                                                <th className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">

                                                </th>
                                                <th className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">

                                                </th>
                                                <th className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">

                                                </th>
                                                <th className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">

                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-white ">
                                            <tr className="animate-pulse">
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                            </tr>
                                            <tr className="animate-pulse">
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                            </tr>
                                            <tr className="animate-pulse">
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                            </tr>
                                            <tr className="animate-pulse">
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                            </tr>
                                            <tr className="animate-pulse">
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                            </tr>
                                            <tr className="animate-pulse">
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                            </tr>
                                            <tr className="animate-pulse">
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                            </tr>
                                            <tr className="animate-pulse">
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                            </tr>
                                            <tr className="animate-pulse">
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                            </tr>
                                            <tr className="animate-pulse">
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                            </tr>
                                            <tr className="animate-pulse">
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    ) :
                    (
                        <>
                            <div className='w-[95%] mt-8 mx-auto'>
                                <div className="w-full shadow-md sm:rounded-lg overflow-x-auto">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-700">
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                                                    Name
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                                                    Franchise Name
                                                </th>

                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                                                    Mobile Number
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                                                    Email
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                                                    Role
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                                                    Status
                                                </th>

                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                                                    Delete Access
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200 text-center">
                                            {userByeAuth?.map((item, index) => (
                                                <tr key={index} className=''>
                                                    <td className={`px-6 py-4 whitespace-nowrap ${item?.role === "franchise" && "bg-gray-300"}`} >{item?.name}</td>
                                                    <td className={`px-6 py-4 whitespace-nowrap ${item?.role === "franchise" && "bg-gray-300 text-[#FF0000] font-medium uppercase"} `} >{item?.role === "franchise" ? `${item?.franchisename}` : item?.franchisename}</td>
                                                    <td className={`px-6 py-4 whitespace-nowrap ${item?.role === "franchise" && "bg-gray-300"}`} >{item?.mobile}</td>
                                                    <td className={`px-6 py-4 whitespace-nowrap ${item?.role === "franchise" && "bg-gray-300"}`} >{item?.email}</td>
                                                    <td className={`px-6 py-4 whitespace-nowrap ${item?.role === "franchise" && "bg-gray-300"}`} >{item?.role}</td>
                                                    <td className={`px-6 py-4 whitespace-nowrap ${item?.role === "franchise" && "bg-gray-300"}`} >{item?.status ? "Active" : "InActive"}</td>
                                                    <td className={`px-6 py-4 whitespace-nowrap ${item?.role === "franchise" && "bg-gray-300"}`} >
                                                        <button onClick={()=>deleteLogin(item?._id)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Delete Access</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    )
            }

        </div>
    )
}

export default TotalRegister