import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Http from '../../Services/Http';
import { Link, useNavigate } from 'react-router-dom';

const LoginForm = () => {
  const [danger, setdanger] = useState(false);
  const [succes, setsucces] = useState(false);

  const navigate = useNavigate()

  const initialValues = {
    email: '',
    password: '',
    remember: false,
  };

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string().required('Required'),
  });

  const handleSubmit = async (values) => {
    const email = values?.email;
    const password = values?.password;
    try {
      let res = await Http({
        url: '/auth/loginuser',
        method: 'post',
        data: { email, password },
      });
      let token = res?.data?.token;
      let userdata = res?.data?.userdata;
      if (token) {
        localStorage.setItem('token', token);
        const userString = JSON.stringify(userdata);
        localStorage.setItem('userdata', userString)
        setTimeout(() => {
          navigate('/home/dashboardpage');
          setsucces(false)
        }, 2000)
        setsucces(true)
      }
      if (res?.data?.message === "incorrect_email" || res?.data?.message === "password_incorrect") {
        setdanger(true);
        setTimeout(() => {
          setdanger(false);
        }, 2000);
      }
    } catch (error) {
      console.log('catch error');
    }
  };

  return (
    <div className='relative'>
      <div className="w-full bg-gray-100 h-screen">
        <div className="p-8 lg:w-1/2 mx-auto">
          <div className=" rounded-t-lg p-8">
            <h1 className=" flex justify-center"><Link to={'/'} className='w-20  mb-2 rounded-md  text-center text-sm bg-gray-700 text-gray-200 px-4 py-2 font-light'>Home</Link></h1>
            <div>

            </div>
          </div>
          <div className="bg-gray-100 rounded-b-lg py-12 px-4 lg:px-24">
            <p className="text-center text-sm text-gray-500 font-light">Or sign in with credentials</p>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form className="mt-6">
                <div className="relative">
                  <Field
                    name="email"
                    type="text"
                    placeholder="Email"
                    className="appearance-none border pl-12 border-gray-100 shadow-sm focus:shadow-md focus:placeholder-gray-600  transition  rounded-md w-full py-3 text-gray-600 leading-tight focus:outline-none focus:ring-gray-600 focus:shadow-outline"
                  />
                  <div className="absolute left-0 inset-y-0 flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 ml-3 text-gray-400 p-1" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                    </svg>
                  </div>


                </div>
                <p className="text-red-500 h-5 text-sm mt-1 ml-2" >
                  <ErrorMessage name="email" component="div" />
                </p>
                <div className="relative mt-3">
                  <Field
                    name="password"
                    type="password"
                    placeholder="Password"
                    className="appearance-none border pl-12 border-gray-100 shadow-sm focus:shadow-md focus:placeholder-gray-600  transition  rounded-md w-full py-3 text-gray-600 leading-tight focus:outline-none focus:ring-gray-600 focus:shadow-outline"
                  />
                  <div className="absolute left-0 inset-y-0 flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 ml-3 text-gray-400 p-1" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z" />
                    </svg>
                  </div>

                </div>
                <p className="text-red-500 h-5 text-sm mt-1 ml-2" >
                  <ErrorMessage name="password" component="div" />
                </p>
                <div className='flex justify-between'>
                  <div className="mt-4 flex items-center text-gray-500">
                    <Field type="checkbox" id="remember" name="remember" className="mr-3" />
                    <label htmlFor="remember">Remember me</label>
                  </div>
                  <div className="mt-4 flex items-center text-gray-500">

                    <Link to={'/forgetpage'} className='cursor-pointer'>Forget password</Link>
                  </div>
                </div>
                <div className="flex items-center justify-center mt-8">
                  <button type="submit" className="text-white py-2 px-4 uppercase rounded bg-indigo-500 hover:bg-indigo-600 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5">Login</button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      {
        danger &&
        <>
          <div
            id="alert-border-2"
            className="flex absolute z-50 top-2 md:left-[43%] items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400 dark:bg-gray-800 dark:border-red-800"
            role="alert"
          >
            <svg
              className="flex-shrink-0 w-4 h-4"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <div className="ms-3 text-sm font-medium">
              incorrect login details
            </div>
            <button
              type="button"
              className="ms-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-red-400 dark:hover:bg-gray-700"
              data-dismiss-target="#alert-border-2"
              aria-label="Close"
            >
              <span className="sr-only">Dismiss</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>

        </>
      }
      {
        succes &&
        <>
          <div
            id="alert-border-3"
            className="flex absolute z-50 top-2 md:left-[43%] items-center p-4 mb-4 text-green-800 border-t-4 border-green-300 bg-green-50 dark:text-green-400 dark:bg-gray-800 dark:border-green-800"
            role="alert"
          >
            <svg
              className="flex-shrink-0 w-4 h-4"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <div className="ms-3 text-sm font-medium">
              login successful
            </div>
            <button
              type="button"
              className="ms-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-green-400 dark:hover:bg-gray-700"
              data-dismiss-target="#alert-border-3"
              aria-label="Close"
            >
              <span className="sr-only">Dismiss</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>

        </>
      }
    </div>
  );
};

export default LoginForm;
